import { lazy } from 'react'
import {
  mdiAccountBoxMultipleOutline,
  mdiAccountMultiple,
  mdiBriefcaseEditOutline,
  mdiChartBarStacked,
  mdiCog,
  mdiFastForward,
  mdiNoteMultipleOutline,
  mdiPost,
  mdiReceipt,
  mdiViewDashboard,
  mdiWifi
} from '@mdi/js'
import MaterialIcon from '@/components/icon/MaterialIcon'
import i18n from '@/i18n'
import { Role } from '@/models/role'
import { CustomRouteProps } from './CustomRoute'

export default [
  {
    name: 'login',
    path: '/login',
    component: lazy(() => import('@/views/auth/login/Login'))
  },
  {
    name: 'forgottenPassword',
    path: '/forgotten-password',
    component: lazy(() => import('@/views/auth/forgottenPassword/ForgottenPassword'))
  },
  {
    name: 'registration',
    path: '/registration',
    component: lazy(() => import('@/views/auth/registration/Registration'))
  },
  {
    name: 'passwordReset',
    path: '/passwordreset',
    component: lazy(() => import('@/views/auth/passwordReset/PasswordReset'))
  },
  {
    name: 'changeEmail',
    path: '/changeemail',
    component: lazy(() => import('@/views/auth/changeEmail/ChangeEmail'))
  },
  {
    name: 'invoiceDownload',
    path: '/invoicedownload',
    component: lazy(() => import('@/views/auth/invoiceDownload/InvoiceDownload'))
  },

  // admin routes
  {
    name: 'adminCreateResident',
    path: '/customers/create-resident',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminCustomers/resident/ResidentEditPage'))
  },
  {
    name: 'adminCreateCompany',
    path: '/customers/create-company',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminCustomers/company/CompanyEditPage'))
  },
  {
    name: 'adminCreateCompany',
    path: '/customers/:id/edit',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminCustomers/AdminCustomerEditPage'))
  },
  {
    name: 'adminCustomerProfile',
    path: '/customers/:id',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminCustomers/customerProfile/CustomerProfilePage'))
  },
  {
    name: 'adminCustomers',
    path: '/customers',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminCustomers/AdminCustomersPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.customers'),
      icon: <MaterialIcon path={mdiAccountBoxMultipleOutline} />
    }
  },
  {
    name: 'adminContractEdit',
    path: '/contracts/:mode(create|edit)/:id?',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminContracts/edit/ContractEdit'))
  },
  {
    name: 'adminContracts',
    path: '/contracts/:id',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminContracts/details/ContractDetailsPage'))
  },
  {
    name: 'adminContracts',
    path: '/contracts',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminContracts/list/ContractsListPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.admin-contracts'),
      icon: <MaterialIcon path={mdiBriefcaseEditOutline} />
    }
  },
  {
    name: 'adminDevices',
    path: '/devices/:id',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminDevices/components/details/AdminDeviceDetailsPage'))
  },
  {
    name: 'adminDevices',
    path: '/devices',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminDevices/list/AdminDevicesPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.admin-devices'),
      icon: <MaterialIcon path={mdiWifi} />
    }
  },
  {
    name: 'adminInvoices',
    path: '/invoices',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminInvoices/AdminInvoiceListPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.admin-invoicing'),
      icon: <MaterialIcon path={mdiReceipt} />
    }
  },
  {
    name: 'adminCharts',
    path: '/charts',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminCharts/AdminChartsPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.charts'),
      icon: <MaterialIcon path={mdiChartBarStacked} />
    }
  },
  {
    name: 'adminForecasts',
    path: '/forecasts',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/forecast/ForecastPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.forecasts'),
      icon: <MaterialIcon path={mdiFastForward} />
    }
  },
  {
    name: 'auditLog',
    path: '/auditlog',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminAuditlog/AuditLogPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.audit-log'),
      icon: <MaterialIcon path={mdiPost} />
    }
  },
  {
    name: 'settings',
    path: '/settings',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminSettings/SettingsPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.settings'),
      icon: <MaterialIcon path={mdiCog} />
    }
  },

  {
    name: 'adminUserProfile',
    path: '/users/:id',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminProfile/AdminProfilePage'))
  },
  {
    name: 'adminUsers',
    path: '/users',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/adminUsers/UsersPage')),
    menuOptions: {
      showMenu: true,
      right: true,
      title: i18n.t('navigation.admin-users'),
      icon: <MaterialIcon path={mdiAccountMultiple} />
    }
  },
  {
    name: 'profile',
    path: '/me',
    requiredRole: [Role.Admin],
    component: lazy(() => import('@/views/me/adminProfile/AdminProfilePage'))
  },

  // user routes
  {
    name: 'controlCenter',
    path: '/control-center',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/customerControlCenter/ControlCenterPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.control-center'),
      icon: <MaterialIcon path={mdiViewDashboard} />
    }
  },
  {
    name: 'userInvoices',
    path: '/invoices',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/customerInvoices/CustomerInvoiceListPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.user-invoices'),
      icon: <MaterialIcon path={mdiNoteMultipleOutline} />
    }
  },
  {
    name: 'userContracts',
    path: '/contracts/:id',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/customerContracts/details/CustomerContractDetails'))
  },
  {
    name: 'userContracts',
    path: '/contracts',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/customerContracts/CustomerContractsPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.user-contracts'),
      icon: <MaterialIcon path={mdiBriefcaseEditOutline} />
    }
  },
  {
    name: 'userDevices',
    path: '/devices/:id',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/customerDevices/details/CustomerDeviceDetailsPage'))
  },
  {
    name: 'userDevices',
    path: '/devices',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/customerDevices/CustomerDeviceListPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.user-devices'),
      icon: <MaterialIcon path={mdiWifi} />
    }
  },
  {
    name: 'userCharts',
    path: '/charts',
    requiredRole: [Role.User],
    component: lazy(
      () => import('@/views/customerCharts/components/customerConsumptionChartsPage/CustomerConsumptionChartsPage')
    ),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.charts'),
      icon: <MaterialIcon path={mdiChartBarStacked} />
    }
  },
  {
    name: 'userForecasts',
    path: '/forecasts',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/forecast/ForecastPage')),
    menuOptions: {
      showMenu: true,
      title: i18n.t('navigation.forecasts'),
      icon: <MaterialIcon path={mdiFastForward} />
    }
  },
  {
    name: 'profile',
    path: '/me',
    requiredRole: [Role.User],
    component: lazy(() => import('@/views/me/customerProfile/CustomerProfilePage'))
  }

  // shared routes
] as CustomRouteProps[]
