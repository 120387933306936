import axiosLib, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import userService from '@/services/user'
import { store } from '@/store'
import { api } from '.'

let isTokenRefreshing = false
const axios = axiosLib.create()
const pendingRequests: AxiosRequestConfig[] = []

const processPendingRequest = (): AxiosPromise<any> | undefined => {
  const config = pendingRequests.shift() // get next config
  if (!config) {
    return
  }

  const { accessToken } = userService.getToken()
  config.headers.Authorization = `Bearer ${accessToken}`
  return axios(config)
}

const refreshToken = async (config: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
  if (isTokenRefreshing) {
    pendingRequests.push(config)
  }

  return new Promise((resolve, reject) => {
    const { refreshToken } = userService.getToken()
    isTokenRefreshing = true

    api.auth
      .refreshToken({
        refreshTokenDto: { refreshToken: `${refreshToken}` }
      })
      .then(({ data }) => {
        userService.setUser(data)
        config.headers.Authorization = `Bearer ${data.accessToken}`

        resolve(axios(config))
        processPendingRequest()
      })
      .catch(() => {
        store.dispatch.auth.removeUser()
        userService.removeUser()

        reject()
      })
      .finally(() => {
        isTokenRefreshing = false
      })
  })
}

axios.interceptors.response.use(
  (resp: AxiosResponse) => {
    processPendingRequest()

    return resp
  },
  async (error: any): Promise<any> => {
    const { responseURL, status } = error?.request

    // handle 401 response
    if (status === 401 && !responseURL.endsWith('/Auth/Logout')) {
      return refreshToken(error.config)
    }

    // reject the request
    return Promise.reject(error.response?.data)
  }
)
export default axios
