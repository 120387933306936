import { useState } from 'react'
import { Form, FormInstance } from 'antd'
import { getAllRequired, SchemaKey } from '@/utils/schema'

export interface FormUtils<Values = any> {
  schema?: SchemaKey
  form: FormInstance<Values>
  submittable: boolean
  required: string[]
  setFieldsValue: (fields: any, silent?: boolean) => void
  setInitialValues: (fields: any) => void
  handleFieldsChanged: () => void
}

export type FormUtilsProps = {
  schema?: SchemaKey
  requiredFields?: string[]
}

const useFormUtils = (schemaName?: SchemaKey | string[], requiredItems?: string[]): FormUtils => {
  const schema = Array.isArray(schemaName) ? undefined : schemaName
  const requiredFields = Array.isArray(schemaName) ? schemaName : requiredItems
  const [form] = Form.useForm()
  const [submittable, setSubmittable] = useState<boolean>(false)
  const schemaRequired = (schema && getAllRequired(schema)) || []
  const required = [...(requiredFields || []), ...schemaRequired]

  const handleFieldsChanged = (): void => {
    const isAllRequiredFilled = required.every((fieldName: string) => {
      const namePath = fieldName?.split('.')

      if (form.getFieldInstance(namePath)) {
        const value = form.getFieldValue(namePath)
        return typeof value === 'string' ? value.trim() : value
      }

      // optional or conditionally rendered fields
      return true
    })

    const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length).length
    setSubmittable(isAllRequiredFilled && !hasErrors)
  }

  const setFieldsValue = (fields: any, silent = false): void => {
    form.setFieldsValue(fields)

    if (!silent) {
      setTimeout(handleFieldsChanged, 0)
    }
  }

  const setInitialValues = (fields: any): void => {
    setFieldsValue(fields, true)
  }

  return {
    form,
    schema,
    required,
    submittable,
    setFieldsValue,
    setInitialValues,
    handleFieldsChanged
  }
}

export default useFormUtils
