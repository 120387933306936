import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import immerPlugin from '@rematch/immer'
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { models, RootModel } from './models'
import errorPlugin from './plugins/errorPlugin'

type FullModel = ExtraModelsFromLoading<RootModel, { type: 'full' }>

export const history = createBrowserHistory()
export const store = init<RootModel, FullModel>({
  redux: {
    reducers: {
      router: connectRouter(history)
    },
    rootReducers: {
      RESET: () => undefined
    },
    middlewares: [routerMiddleware(history)]
  },
  models,
  plugins: [immerPlugin({ blacklist: ['loading'] }), loadingPlugin({ type: 'full' }), errorPlugin()]
})

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel, FullModel>
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
export const useDispatch = (): Dispatch => useReduxDispatch<Dispatch>()
