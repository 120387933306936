import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalProps, Skeleton } from 'antd'
import classNames from 'classnames'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import { FormUtils } from '@/hooks/useFormUtils'
import styles from './CustomModal.module.scss'

declare type ModalSizes = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | undefined

const modalSizes = {
  xxl: 'xxl',
  xl: 'xl',
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs'
}

export interface CustomModalProps extends ModalProps {
  loading?: boolean
  loadingContent?: boolean
  formUtils?: FormUtils
  size?: ModalSizes
}

const CustomModal: FC<CustomModalProps> = ({
  visible,
  children,
  okText,
  size = modalSizes.md,
  className,
  loadingContent,
  loading,
  formUtils,
  onOk,
  okButtonProps,
  ...modalProps
}) => {
  const { t } = useTranslation()
  const isSmall = useBreakpoint()

  return (
    <Modal
      visible={visible}
      cancelText={t('common.cancel')}
      cancelButtonProps={{ type: 'link' }}
      okText={okText || t('common.save')}
      className={classNames(
        styles.modal,
        {
          [styles.sizeTiny]: isSmall,
          [styles.sizeExtraLarge]: !isSmall && size === modalSizes.xl,
          [styles.sizeLarge]: !isSmall && size === modalSizes.lg,
          [styles.sizeDefault]: !isSmall && size === modalSizes.md,
          [styles.sizeSmall]: !isSmall && size === modalSizes.xs
        },
        className
      )}
      okButtonProps={{
        loading,
        type: 'primary',
        disabled: formUtils && !formUtils?.submittable,
        onClick: onOk || (formUtils && formUtils?.form.submit),
        ...okButtonProps
      }}
      {...modalProps}
    >
      <Skeleton loading={loadingContent}>{children}</Skeleton>
    </Modal>
  )
}

export default CustomModal
