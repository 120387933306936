/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckTransactionResultDto } from '../models';
// @ts-ignore
import { PayResultDto } from '../models';
/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check transaction result.
         * @param {string} payRefNo PayRefNo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTransactionResult: async (payRefNo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payRefNo' is not null or undefined
            assertParamExists('checkTransactionResult', 'payRefNo', payRefNo)
            const localVarPath = `/api/Payment/CheckTransactionCompleted/{payRefNo}`
                .replace(`{${"payRefNo"}}`, encodeURIComponent(String(payRefNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pay.
         * @param {number} invoiceId Invoice id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pay: async (invoiceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('pay', 'invoiceId', invoiceId)
            const localVarPath = `/api/Payment/Pay/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check transaction result.
         * @param {string} payRefNo PayRefNo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkTransactionResult(payRefNo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckTransactionResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkTransactionResult(payRefNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pay.
         * @param {number} invoiceId Invoice id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pay(invoiceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pay(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Check transaction result.
         * @param {string} payRefNo PayRefNo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTransactionResult(payRefNo: string, options?: any): AxiosPromise<CheckTransactionResultDto> {
            return localVarFp.checkTransactionResult(payRefNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pay.
         * @param {number} invoiceId Invoice id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pay(invoiceId: number, options?: any): AxiosPromise<PayResultDto> {
            return localVarFp.pay(invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkTransactionResult operation in PaymentApi.
 * @export
 * @interface PaymentApiCheckTransactionResultRequest
 */
export interface PaymentApiCheckTransactionResultRequest {
    /**
     * PayRefNo.
     * @type {string}
     * @memberof PaymentApiCheckTransactionResult
     */
    readonly payRefNo: string
}

/**
 * Request parameters for pay operation in PaymentApi.
 * @export
 * @interface PaymentApiPayRequest
 */
export interface PaymentApiPayRequest {
    /**
     * Invoice id.
     * @type {number}
     * @memberof PaymentApiPay
     */
    readonly invoiceId: number
}

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Check transaction result.
     * @param {PaymentApiCheckTransactionResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public checkTransactionResult(requestParameters: PaymentApiCheckTransactionResultRequest, options?: any) {
        return PaymentApiFp(this.configuration).checkTransactionResult(requestParameters.payRefNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pay.
     * @param {PaymentApiPayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public pay(requestParameters: PaymentApiPayRequest, options?: any) {
        return PaymentApiFp(this.configuration).pay(requestParameters.invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}
