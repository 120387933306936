import { ComponentType, FC, ReactElement, Suspense } from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router'
import Loading from '@/components/layout/Loading'
import LoginLayout from '@/components/layout/LoginLayout'
import PrivateLayout from '@/components/layout/PrivateLayout'
import { Role } from '@/models/role'

export interface CustomRouteProps extends RouteProps {
  name: string
  component: ComponentType<RouteComponentProps>
  requiredRole?: Role[]
  menuOptions?: {
    showMenu?: boolean
    title?: string
    right?: boolean
    icon?: ReactElement
  }
}

const CustomRoute: FC<CustomRouteProps> = ({ requiredRole, component: Component, ...routeProps }) => {
  const isPrivate = !!requiredRole?.length
  const Layout = (isPrivate && PrivateLayout) || LoginLayout

  return (
    <Route
      {...routeProps}
      exact
      render={props => (
        <Layout name={routeProps.name}>
          <Suspense fallback={<Loading absolute={isPrivate} />}>
            <Component {...props} />
          </Suspense>
        </Layout>
      )}
    />
  )
}

export default CustomRoute
