import { createModel } from '@rematch/core'
import { push } from 'connected-react-router'
import { api } from '@/api'
import { SignInDto } from '@/api/generated'
import { User } from '@/models/user'
import userService from '@/services/user'
import type { RootModel } from '.'

type AuthState = {
  user?: User
}

const user = userService.getUser()
export const auth = createModel<RootModel>()({
  state: {
    user
  } as AuthState,
  reducers: {
    setUser(state, payload: User) {
      state.user = payload
    },
    removeUser(state) {
      state.user = undefined
    }
  },
  effects: dispatch => {
    const { auth } = dispatch

    return {
      async login(signInDto: SignInDto): Promise<User> {
        const { data } = await api.auth.login({ signInDto })
        const user = userService.setUser(data)

        auth.setUser(user)
        return user
      },

      async logout(): Promise<void> {
        const { accessToken, refreshToken } = userService.getToken()
        userService.removeUser()
        dispatch({ type: 'RESET' })
        dispatch(push('/login'))

        if (refreshToken) {
          api.auth.logout(
            { revokeRefreshTokenCommand: { refreshToken } },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          )
        }
      }
    }
  }
})
