/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMonthlyMaintenanceCostDto } from '../models';
// @ts-ignore
import { IdResponseDto } from '../models';
// @ts-ignore
import { MonthlyMaintenanceCostDto } from '../models';
// @ts-ignore
import { MonthlyMaintenanceCostDtoPaginatedList } from '../models';
/**
 * MonthlyMaintenanceCostApi - axios parameter creator
 * @export
 */
export const MonthlyMaintenanceCostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create monthly meintenance cost.
         * @param {CreateMonthlyMaintenanceCostDto} [createMonthlyMaintenanceCostDto] Request parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMonthlyMaintenanceCost: async (createMonthlyMaintenanceCostDto?: CreateMonthlyMaintenanceCostDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MonthlyMaintenanceCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMonthlyMaintenanceCostDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get last maintenance cost.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastMonthlyMaintenanceCost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MonthlyMaintenanceCost/Last`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get maintenance costs.
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {number} [cost] 
         * @param {string} [lastModifiedById] 
         * @param {string} [lastModifiedByName] 
         * @param {Array<string>} [lastModified] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyMaintenanceCost: async (from?: Array<string>, to?: Array<string>, cost?: number, lastModifiedById?: string, lastModifiedByName?: string, lastModified?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MonthlyMaintenanceCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (from) {
                localVarQueryParameter['From'] = from;
            }

            if (to) {
                localVarQueryParameter['To'] = to;
            }

            if (cost !== undefined) {
                localVarQueryParameter['Cost'] = cost;
            }

            if (lastModifiedById !== undefined) {
                localVarQueryParameter['LastModifiedById'] = lastModifiedById;
            }

            if (lastModifiedByName !== undefined) {
                localVarQueryParameter['LastModifiedByName'] = lastModifiedByName;
            }

            if (lastModified) {
                localVarQueryParameter['LastModified'] = lastModified;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get monthly maintenance cost by id.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyMaintenanceCostById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMonthlyMaintenanceCostById', 'id', id)
            const localVarPath = `/api/MonthlyMaintenanceCost/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonthlyMaintenanceCostApi - functional programming interface
 * @export
 */
export const MonthlyMaintenanceCostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonthlyMaintenanceCostApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create monthly meintenance cost.
         * @param {CreateMonthlyMaintenanceCostDto} [createMonthlyMaintenanceCostDto] Request parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMonthlyMaintenanceCost(createMonthlyMaintenanceCostDto?: CreateMonthlyMaintenanceCostDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMonthlyMaintenanceCost(createMonthlyMaintenanceCostDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get last maintenance cost.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastMonthlyMaintenanceCost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyMaintenanceCostDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastMonthlyMaintenanceCost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get maintenance costs.
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {number} [cost] 
         * @param {string} [lastModifiedById] 
         * @param {string} [lastModifiedByName] 
         * @param {Array<string>} [lastModified] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyMaintenanceCost(from?: Array<string>, to?: Array<string>, cost?: number, lastModifiedById?: string, lastModifiedByName?: string, lastModified?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyMaintenanceCostDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyMaintenanceCost(from, to, cost, lastModifiedById, lastModifiedByName, lastModified, skip, take, orderBy, orderByAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get monthly maintenance cost by id.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyMaintenanceCostById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyMaintenanceCostDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyMaintenanceCostById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonthlyMaintenanceCostApi - factory interface
 * @export
 */
export const MonthlyMaintenanceCostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonthlyMaintenanceCostApiFp(configuration)
    return {
        /**
         * 
         * @summary Create monthly meintenance cost.
         * @param {CreateMonthlyMaintenanceCostDto} [createMonthlyMaintenanceCostDto] Request parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMonthlyMaintenanceCost(createMonthlyMaintenanceCostDto?: CreateMonthlyMaintenanceCostDto, options?: any): AxiosPromise<IdResponseDto> {
            return localVarFp.createMonthlyMaintenanceCost(createMonthlyMaintenanceCostDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get last maintenance cost.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastMonthlyMaintenanceCost(options?: any): AxiosPromise<MonthlyMaintenanceCostDto> {
            return localVarFp.getLastMonthlyMaintenanceCost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get maintenance costs.
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {number} [cost] 
         * @param {string} [lastModifiedById] 
         * @param {string} [lastModifiedByName] 
         * @param {Array<string>} [lastModified] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyMaintenanceCost(from?: Array<string>, to?: Array<string>, cost?: number, lastModifiedById?: string, lastModifiedByName?: string, lastModified?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): AxiosPromise<MonthlyMaintenanceCostDtoPaginatedList> {
            return localVarFp.getMonthlyMaintenanceCost(from, to, cost, lastModifiedById, lastModifiedByName, lastModified, skip, take, orderBy, orderByAsc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get monthly maintenance cost by id.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyMaintenanceCostById(id: number, options?: any): AxiosPromise<MonthlyMaintenanceCostDto> {
            return localVarFp.getMonthlyMaintenanceCostById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createMonthlyMaintenanceCost operation in MonthlyMaintenanceCostApi.
 * @export
 * @interface MonthlyMaintenanceCostApiCreateMonthlyMaintenanceCostRequest
 */
export interface MonthlyMaintenanceCostApiCreateMonthlyMaintenanceCostRequest {
    /**
     * Request parameter.
     * @type {CreateMonthlyMaintenanceCostDto}
     * @memberof MonthlyMaintenanceCostApiCreateMonthlyMaintenanceCost
     */
    readonly createMonthlyMaintenanceCostDto?: CreateMonthlyMaintenanceCostDto
}

/**
 * Request parameters for getMonthlyMaintenanceCost operation in MonthlyMaintenanceCostApi.
 * @export
 * @interface MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostRequest
 */
export interface MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly from?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly to?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly cost?: number

    /**
     * 
     * @type {string}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly lastModifiedById?: string

    /**
     * 
     * @type {string}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly lastModifiedByName?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly lastModified?: Array<string>

    /**
     * Skip items for paging.
     * @type {number}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly skip?: number

    /**
     * Take items for paging.
     * @type {number}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly take?: number

    /**
     * Order by column name.
     * @type {string}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly orderBy?: string

    /**
     * Is order by ascending or descending.
     * @type {boolean}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCost
     */
    readonly orderByAsc?: boolean
}

/**
 * Request parameters for getMonthlyMaintenanceCostById operation in MonthlyMaintenanceCostApi.
 * @export
 * @interface MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostByIdRequest
 */
export interface MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostByIdRequest {
    /**
     * Id.
     * @type {number}
     * @memberof MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostById
     */
    readonly id: number
}

/**
 * MonthlyMaintenanceCostApi - object-oriented interface
 * @export
 * @class MonthlyMaintenanceCostApi
 * @extends {BaseAPI}
 */
export class MonthlyMaintenanceCostApi extends BaseAPI {
    /**
     * 
     * @summary Create monthly meintenance cost.
     * @param {MonthlyMaintenanceCostApiCreateMonthlyMaintenanceCostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonthlyMaintenanceCostApi
     */
    public createMonthlyMaintenanceCost(requestParameters: MonthlyMaintenanceCostApiCreateMonthlyMaintenanceCostRequest = {}, options?: any) {
        return MonthlyMaintenanceCostApiFp(this.configuration).createMonthlyMaintenanceCost(requestParameters.createMonthlyMaintenanceCostDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get last maintenance cost.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonthlyMaintenanceCostApi
     */
    public getLastMonthlyMaintenanceCost(options?: any) {
        return MonthlyMaintenanceCostApiFp(this.configuration).getLastMonthlyMaintenanceCost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get maintenance costs.
     * @param {MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonthlyMaintenanceCostApi
     */
    public getMonthlyMaintenanceCost(requestParameters: MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostRequest = {}, options?: any) {
        return MonthlyMaintenanceCostApiFp(this.configuration).getMonthlyMaintenanceCost(requestParameters.from, requestParameters.to, requestParameters.cost, requestParameters.lastModifiedById, requestParameters.lastModifiedByName, requestParameters.lastModified, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get monthly maintenance cost by id.
     * @param {MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonthlyMaintenanceCostApi
     */
    public getMonthlyMaintenanceCostById(requestParameters: MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostByIdRequest, options?: any) {
        return MonthlyMaintenanceCostApiFp(this.configuration).getMonthlyMaintenanceCostById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
