import { createModel, RematchRootState } from '@rematch/core'
import { api } from '@/api'
import {
  CheckTransactionResultDto,
  InvoiceApiCancelInvoiceRequest,
  InvoiceApiGetInvoicesRequest,
  InvoiceApiPayInvoiceRequest,
  InvoiceApiPublicDownloadInvoiceRequest,
  InvoiceApiRevokeInvoiceRequest,
  InvoiceApiSetInvoiceSentDateRequest,
  InvoiceListDto,
  PaymentApiCheckTransactionResultRequest,
  PaymentApiPayRequest,
  PayResultDto,
  SetInvoiceGroupSentDateCommand
} from '@/api/generated'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import { downloadBlob } from '@/utils/file/downloadBlob'
import type { RootModel } from '.'

type InvoiceState = {
  invoice?: InvoiceListDto
  invoices?: InvoiceListDto[]
  invoiceListParams?: ListRequestParams
}

export const invoice = createModel<RootModel>()({
  state: {} as InvoiceState,
  reducers: {
    reset(state) {
      state.invoice = undefined
      state.invoices = []
      state.invoiceListParams = {}
    },
    resetInvoice(state) {
      state.invoice = undefined
    },
    setInvoice(state, invoice?: InvoiceListDto) {
      state.invoice = invoice
    },
    setInvoices(state, payload: { invoices: InvoiceListDto[]; listParams: ListRequestParams }) {
      state.invoices = payload.invoices
      state.invoiceListParams = payload.listParams
    }
  },
  effects: dispatch => {
    const { invoice } = dispatch

    return {
      async getInvoice(params: InvoiceApiGetInvoicesRequest = {}): Promise<void> {
        const {
          data: { result }
        } = await api.invoice.getInvoices(params)

        invoice.setInvoice(result?.[0])
      },

      async getInvoices(
        params: InvoiceApiGetInvoicesRequest = {},
        state: RematchRootState<RootModel>
      ): Promise<{ result?: InvoiceListDto[] | null; listParams: ListRequestParams }> {
        const requestParams = { ...state.invoice.invoiceListParams, ...params }

        const {
          data: { result, ...listParams }
        } = await api.invoice.getInvoices(requestParams)

        invoice.setInvoices({ invoices: result || [], listParams })
        return { result, listParams }
      },

      // admin only
      async setInvoicePaid({ id }: InvoiceApiPayInvoiceRequest): Promise<void> {
        await api.invoice.payInvoice({ id })
      },

      async setInvoiceSentDate(params: InvoiceApiSetInvoiceSentDateRequest): Promise<void> {
        await api.invoice.setInvoiceSentDate(params)
      },

      async setInvoiceGroupSentDate(setInvoiceGroupSentDateCommand: SetInvoiceGroupSentDateCommand): Promise<string[]> {
        const { data } = await api.invoice.setInvoiceGroupSentDate({ setInvoiceGroupSentDateCommand })
        return data
      },

      async payInvoice(params: PaymentApiPayRequest): Promise<PayResultDto> {
        const { data } = await api.payment.pay(params)
        return data
      },

      async checkInvoice(params: PaymentApiCheckTransactionResultRequest): Promise<CheckTransactionResultDto> {
        const { data } = await api.payment.checkTransactionResult(params)
        return data
      },

      async revokeInvoice(params: InvoiceApiRevokeInvoiceRequest): Promise<void> {
        await api.invoice.revokeInvoice(params)
      },

      async cancelInvoice({ id }: InvoiceApiCancelInvoiceRequest): Promise<void> {
        await api.invoice.cancelInvoice({ id })
      },

      async downloadInvoice({ requestBody, fileName }): Promise<void> {
        const { data } = await api.invoice.downloadInvoice({ requestBody }, { responseType: 'blob' })
        downloadBlob(data, fileName)
      },

      async publicDownloadInvoice({ token }: InvoiceApiPublicDownloadInvoiceRequest): Promise<void> {
        const { data, headers } = await api.invoice.publicDownloadInvoice({ token }, { responseType: 'blob' })
        const [, fileName] = headers['content-disposition']?.match('filename=([^;]*)')

        downloadBlob(data, fileName)
      }
    }
  }
})
