import { createModel, RematchRootState } from '@rematch/core'
import { api } from '@/api'
import {
  ExtendedUserDto,
  ProfileDto,
  UserApiConfirmEmailUpdateRequest,
  UserApiGetAllRequest,
  UserApiInviteAdminRequest,
  UserApiResendInvitationRequest,
  UserApiUpdateUserAdminRequest
} from '@/api/generated'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import type { RootModel } from '.'

type UsersState = {
  users?: ExtendedUserDto[]
  userListParams?: ListRequestParams
  user?: ProfileDto
}

export const users = createModel<RootModel>()({
  state: {} as UsersState,
  reducers: {
    reset(state) {
      state.users = []
      state.userListParams = {}
    },
    setUsers(state, payload: { users: ExtendedUserDto[]; listParams: ListRequestParams }) {
      state.users = payload.users
      state.userListParams = payload.listParams
    },
    setUser(state, payload: ProfileDto) {
      state.user = payload
    }
  },
  effects: dispatch => {
    const { users } = dispatch

    return {
      async getUsers(params: UserApiGetAllRequest = {}, state: RematchRootState<RootModel>): Promise<void> {
        const requestParams = { ...state.users.userListParams, ...params }

        const {
          data: { result, ...listParams }
        } = await api.user.getAll(requestParams)
        users.setUsers({ users: result || [], listParams })
      },

      async getUser(id: string): Promise<void> {
        const { data } = await api.user.getUserById({ id })
        users.setUser(data)
      },

      async inviteAdminUser({ adminRegistrationDto }: UserApiInviteAdminRequest): Promise<void> {
        await api.user.inviteAdmin({ adminRegistrationDto })
      },

      async resendCustomerInvite({ userId }: UserApiResendInvitationRequest): Promise<void> {
        await api.user.resendInvitation({ userId })
      },

      async updateAdmin({ id, updateUserDto }: UserApiUpdateUserAdminRequest): Promise<void> {
        await api.user.updateUserAdmin({ id, updateUserDto })
      },

      async confirmEmailAddressUpdate({ token }: UserApiConfirmEmailUpdateRequest): Promise<void> {
        await api.user.confirmEmailUpdate({ token })
      },

      async grantAccess(id: string): Promise<void> {
        await api.user.grantAccessUser({ id })
      },

      async lockout(id: string): Promise<void> {
        await api.user.userLockout({ id })
      }
    }
  }
})
