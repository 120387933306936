import { ReactElement } from 'react'
import i18n from '@/i18n'

export declare type StoreValue = any
declare type RuleType =
  | 'string'
  | 'number'
  | 'boolean'
  | 'method'
  | 'regexp'
  | 'integer'
  | 'float'
  | 'object'
  | 'enum'
  | 'date'
  | 'url'
  | 'hex'
  | 'email'

export type BaseRule = {
  enum?: StoreValue[]
  len?: number
  max?: number
  message?: string | ReactElement
  min?: number
  pattern?: RegExp
  required?: boolean
  transform?: (value: StoreValue) => StoreValue
  type?: RuleType
  whitespace?: boolean
  validateTrigger?: string | string[]
}

export const requiredField = (field?: string, rest?: BaseRule): BaseRule => ({
  ...rest,
  transform: (value: any) => `${value}`,
  required: true,
  whitespace: true,
  message: field ? i18n.t('error.common.named-field-required', { field }) : i18n.t('error.common.field-required')
})

export const email = (message?: string): BaseRule => ({
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: message || i18n.t('error.common.email-invalid-format')
})

export const max = (max: number, type: 'string' | 'number', message?: string): BaseRule => {
  const defaultMessage = {
    string: i18n.t('error.common.max-length'),
    number: i18n.t('error.common.max-value')
  }[type]

  return {
    max,
    type,
    message: message || i18n.t(defaultMessage, { max })
  }
}

export const min = (min: number, type: 'string' | 'number', message?: string): BaseRule => {
  const defaultMessage = {
    string: i18n.t('error.common.min-length'),
    number: i18n.t('error.common.min-value')
  }[type]

  return {
    min,
    type,
    transform: (value: any) => `${value}`,
    message: message || i18n.t(defaultMessage, { min })
  }
}

export const pattern = (pattern: RegExp, message?: string): BaseRule => {
  return {
    pattern,
    message: message || i18n.t('error.common.pattern')
  }
}
