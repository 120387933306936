import { createModel, RematchRootState } from '@rematch/core'
import { AxiosResponse } from 'axios'
import { api } from '@/api'
import {
  DeviceTypeApiAssignDeviceTypeToCategoryRequest,
  DeviceTypeApiGetDeviceTypesRequest,
  DeviceTypeListItemDto,
  DeviceTypeListItemDtoPaginatedList
} from '@/api/generated'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import type { RootModel } from '.'

type DeviceTypeState = {
  deviceTypes?: DeviceTypeListItemDto[]
  deviceTypeListParams?: ListRequestParams
}

export const deviceType = createModel<RootModel>()({
  state: {} as DeviceTypeState,
  reducers: {
    reset(state) {
      state.deviceTypes = []
      state.deviceTypeListParams = {}
    },
    setDeviceTypes(state, payload: { deviceTypes: DeviceTypeListItemDto[]; listParams: ListRequestParams }) {
      state.deviceTypes = payload.deviceTypes
      state.deviceTypeListParams = payload.listParams
    }
  },
  effects: dispatch => {
    const { deviceType } = dispatch

    return {
      async getDevicesTypes(
        params: DeviceTypeApiGetDeviceTypesRequest & { isPaired?: boolean } = {},
        state: RematchRootState<RootModel>
      ): Promise<void> {
        const { isPaired, ...rest } = params
        const requestParams = {
          ...state.deviceType.deviceTypeListParams,
          ...rest,
          ...(isPaired === false ? { isPaired: false } : {})
        }

        const {
          data: { result, ...listParams }
        }: AxiosResponse<DeviceTypeListItemDtoPaginatedList> = await api.deviceType.getDeviceTypes(requestParams)

        deviceType.setDeviceTypes({
          deviceTypes: result || [],
          listParams
        })
      },

      async updateDeviceCategory({
        deviceTypeId,
        deviceCategoriesDto
      }: DeviceTypeApiAssignDeviceTypeToCategoryRequest): Promise<void> {
        await api.deviceType.assignDeviceTypeToCategory({
          deviceTypeId,
          deviceCategoriesDto
        })
      }
    }
  }
})
