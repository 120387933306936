import { ReactElement } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router'
import { Role } from '@/models/role'
import { User } from '@/models/user'
import { useSelector } from '@/store'
import { Refresher } from '@/utils/Refresher'
import config from './config'
import CustomRoute, { CustomRouteProps } from './CustomRoute'

export const getDefaultRoute = (user?: User): string => {
  if (user?.role) {
    return (
      {
        [Role.Admin]: '/customers',
        [Role.User]: '/control-center'
      } as { [key: string]: string }
    )[user?.role]
  }

  return '/login'
}

export const getAllowedRouteMap = (): CustomRouteProps[] => {
  const { user } = useSelector(state => state.auth)
  return config.filter(path => {
    if (!path?.requiredRole) {
      return true
    }

    return user?.role && path?.requiredRole?.includes(user.role)
  })
}

const Routes = (): ReactElement => {
  const { user } = useSelector(state => state.auth)
  const allowedRouteMap = getAllowedRouteMap()
  const defaultHome = getDefaultRoute(user)

  return (
    //For refreshing the page if i click on an active menu item
    <Refresher>
      <Switch>
        {allowedRouteMap.map(path => (
          <CustomRoute key={path.name} {...path} />
        ))}
        <Route path="*" render={() => <Redirect to={defaultHome} />} />
      </Switch>
    </Refresher>
  )
}

export const RouterView = withRouter(Routes)
