import { useCallback, useEffect } from 'react'

export default (callback: (props?: any[]) => void, delay: number): void => {
  const cbRef = useCallback(callback, [callback])

  useEffect(() => {
    const handler = (...args: any): void => cbRef(...args)

    if (delay !== null) {
      const timer = setInterval(handler, delay)
      return () => clearInterval(timer)
    }
  }, [cbRef, delay])
}
