import React, { createContext, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react'
import { Form, FormInstance } from 'antd'
import { FormProps } from 'antd/es/form/Form'
import useFormUtils, { FormUtils } from '@/hooks/useFormUtils'
import i18n from '@/i18n'
import { SchemaKey } from '@/utils/schema'
import CustomFormItem from './CustomFormItem'
import CustomFormSubmit from './CustomFormSubmit'

export interface CustomFormProps extends FormProps {
  schema?: SchemaKey
  formUtils?: FormUtils
  loading?: boolean
}

export type CustomFormComponent = ForwardRefExoticComponent<CustomFormProps & RefAttributes<FormInstance<any>>> & {
  Item: typeof CustomFormItem
  Submit: typeof CustomFormSubmit
}

// Form context to get schema props for each field
export const CustomFormContext = createContext({} as any)

const CustomForm = forwardRef(
  ({ formUtils, schema, loading, onFinish, ...formProps }: CustomFormProps, ref?: React.Ref<any>) => {
    const utils = formUtils || useFormUtils(schema)
    const { form, handleFieldsChanged } = utils

    // custom error handler for form field errors
    const handleFinish = async (props: any): Promise<void> => {
      try {
        await onFinish?.(props)
      } catch ({ objectDetail }: any) {
        const fieldErrors =
          (objectDetail as any)?.propertyValidations?.map(({ field, errorCode }: any) => {
            // convert to dot notated camelCase
            const name = field.split('.').map((part: string) => part.replace(/[a-zA-Z]/, value => value.toLowerCase()))
            return { name, errors: [i18n.t(errorCode)] }
          }) || []

        form?.setFields(fieldErrors)
      }
    }

    return (
      <CustomFormContext.Provider
        value={{
          formUtils: utils,
          loading
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFieldsChange={handleFieldsChanged}
          onFinish={handleFinish}
          ref={ref}
          {...formProps}
        />
      </CustomFormContext.Provider>
    )
  }
) as CustomFormComponent

CustomForm.Item = CustomFormItem
CustomForm.Submit = CustomFormSubmit

export default CustomForm
