/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BoolResponseDto } from '../models';
// @ts-ignore
import { ContractDeviceListDtoPaginatedList } from '../models';
// @ts-ignore
import { DeviceAssignDto } from '../models';
// @ts-ignore
import { DeviceCategories } from '../models';
// @ts-ignore
import { DeviceCount } from '../models';
// @ts-ignore
import { DeviceDetailsDto } from '../models';
// @ts-ignore
import { DeviceListDtoPaginatedList } from '../models';
// @ts-ignore
import { DeviceNameDto } from '../models';
// @ts-ignore
import { DismountDto } from '../models';
// @ts-ignore
import { MeasurementDataDto } from '../models';
/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary True if there are any unpaired device.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anyUnpairedDevice: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Device/Unpaired/Any`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign device to contract.
         * @param {number} deviceId Device id.
         * @param {DeviceAssignDto} [deviceAssignDto] Assigned values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDeviceToContract: async (deviceId: number, deviceAssignDto?: DeviceAssignDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('assignDeviceToContract', 'deviceId', deviceId)
            const localVarPath = `/api/Device/{deviceId}/Assign`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceAssignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync devices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Device/Sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get device total counts in device categories.
         * @param {boolean} [onlyUnpaired] If true, fillters paired devices from count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceTotalCounts: async (onlyUnpaired?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Device/TotalCounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (onlyUnpaired !== undefined) {
                localVarQueryParameter['onlyUnpaired'] = onlyUnpaired;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dismount device.
         * @param {number} deviceId Device id.
         * @param {DismountDto} [dismountDto] Dismount dto.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismount: async (deviceId: number, dismountDto?: DismountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('dismount', 'deviceId', deviceId)
            const localVarPath = `/api/Device/{deviceId}/Dismount`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dismountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favourize device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favourizeDevice: async (deviceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('favourizeDevice', 'deviceId', deviceId)
            const localVarPath = `/api/Device/{deviceId}/Favourite`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get device by id.
         * @param {number} id Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceById', 'id', id)
            const localVarPath = `/api/Device/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filterd paginated device list.
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {number} [id] 
         * @param {boolean} [isActive] 
         * @param {string} [productionNumber] 
         * @param {string} [contractNumber] 
         * @param {string} [customerName] 
         * @param {number} [customerId] 
         * @param {string} [address] 
         * @param {Array<DeviceCategories>} [deviceCategory] 
         * @param {string} [name] 
         * @param {boolean} [isPaired] 
         * @param {boolean} [notInUse] 
         * @param {Array<string>} [dismountDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, id?: number, isActive?: boolean, productionNumber?: string, contractNumber?: string, customerName?: string, customerId?: number, address?: string, deviceCategory?: Array<DeviceCategories>, name?: string, isPaired?: boolean, notInUse?: boolean, dismountDate?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (productionNumber !== undefined) {
                localVarQueryParameter['ProductionNumber'] = productionNumber;
            }

            if (contractNumber !== undefined) {
                localVarQueryParameter['ContractNumber'] = contractNumber;
            }

            if (customerName !== undefined) {
                localVarQueryParameter['CustomerName'] = customerName;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (address !== undefined) {
                localVarQueryParameter['Address'] = address;
            }

            if (deviceCategory) {
                localVarQueryParameter['DeviceCategory'] = deviceCategory;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (isPaired !== undefined) {
                localVarQueryParameter['IsPaired'] = isPaired;
            }

            if (notInUse !== undefined) {
                localVarQueryParameter['NotInUse'] = notInUse;
            }

            if (dismountDate) {
                localVarQueryParameter['DismountDate'] = dismountDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get devices for contract.
         * @param {number} contractId Contract id.
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {boolean} [isActive] 
         * @param {string} [productionNumber] 
         * @param {DeviceCategories} [deviceCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesForContract: async (contractId: number, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, isActive?: boolean, productionNumber?: string, deviceCategory?: DeviceCategories, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getDevicesForContract', 'contractId', contractId)
            const localVarPath = `/api/Device/Contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (productionNumber !== undefined) {
                localVarQueryParameter['ProductionNumber'] = productionNumber;
            }

            if (deviceCategory !== undefined) {
                localVarQueryParameter['DeviceCategory'] = deviceCategory;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get measurement data for device.
         * @param {number} id Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementData: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMeasurementData', 'id', id)
            const localVarPath = `/api/Device/{id}/MeasurementData`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink contrct from device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignContract: async (deviceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('unassignContract', 'deviceId', deviceId)
            const localVarPath = `/api/Device/{deviceId}/Unassign`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfavourize device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavourizeDevice: async (deviceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('unfavourizeDevice', 'deviceId', deviceId)
            const localVarPath = `/api/Device/{deviceId}/Favourite`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update device name.
         * @param {number} deviceId Device id.
         * @param {DeviceNameDto} [deviceNameDto] Device new name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceName: async (deviceId: number, deviceNameDto?: DeviceNameDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateDeviceName', 'deviceId', deviceId)
            const localVarPath = `/api/Device/{deviceId}/Name`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceNameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary True if there are any unpaired device.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async anyUnpairedDevice(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoolResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.anyUnpairedDevice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign device to contract.
         * @param {number} deviceId Device id.
         * @param {DeviceAssignDto} [deviceAssignDto] Assigned values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignDeviceToContract(deviceId: number, deviceAssignDto?: DeviceAssignDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignDeviceToContract(deviceId, deviceAssignDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync devices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceSync(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceSync(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get device total counts in device categories.
         * @param {boolean} [onlyUnpaired] If true, fillters paired devices from count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceTotalCounts(onlyUnpaired?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceCount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceTotalCounts(onlyUnpaired, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dismount device.
         * @param {number} deviceId Device id.
         * @param {DismountDto} [dismountDto] Dismount dto.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dismount(deviceId: number, dismountDto?: DismountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dismount(deviceId, dismountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Favourize device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favourizeDevice(deviceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favourizeDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get device by id.
         * @param {number} id Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filterd paginated device list.
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {number} [id] 
         * @param {boolean} [isActive] 
         * @param {string} [productionNumber] 
         * @param {string} [contractNumber] 
         * @param {string} [customerName] 
         * @param {number} [customerId] 
         * @param {string} [address] 
         * @param {Array<DeviceCategories>} [deviceCategory] 
         * @param {string} [name] 
         * @param {boolean} [isPaired] 
         * @param {boolean} [notInUse] 
         * @param {Array<string>} [dismountDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, id?: number, isActive?: boolean, productionNumber?: string, contractNumber?: string, customerName?: string, customerId?: number, address?: string, deviceCategory?: Array<DeviceCategories>, name?: string, isPaired?: boolean, notInUse?: boolean, dismountDate?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceListDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(skip, take, orderBy, orderByAsc, id, isActive, productionNumber, contractNumber, customerName, customerId, address, deviceCategory, name, isPaired, notInUse, dismountDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get devices for contract.
         * @param {number} contractId Contract id.
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {boolean} [isActive] 
         * @param {string} [productionNumber] 
         * @param {DeviceCategories} [deviceCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesForContract(contractId: number, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, isActive?: boolean, productionNumber?: string, deviceCategory?: DeviceCategories, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDeviceListDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesForContract(contractId, skip, take, orderBy, orderByAsc, isActive, productionNumber, deviceCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get measurement data for device.
         * @param {number} id Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementData(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasurementDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlink contrct from device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignContract(deviceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignContract(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unfavourize device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unfavourizeDevice(deviceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unfavourizeDevice(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update device name.
         * @param {number} deviceId Device id.
         * @param {DeviceNameDto} [deviceNameDto] Device new name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceName(deviceId: number, deviceNameDto?: DeviceNameDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceName(deviceId, deviceNameDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary True if there are any unpaired device.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        anyUnpairedDevice(options?: any): AxiosPromise<BoolResponseDto> {
            return localVarFp.anyUnpairedDevice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign device to contract.
         * @param {number} deviceId Device id.
         * @param {DeviceAssignDto} [deviceAssignDto] Assigned values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignDeviceToContract(deviceId: number, deviceAssignDto?: DeviceAssignDto, options?: any): AxiosPromise<void> {
            return localVarFp.assignDeviceToContract(deviceId, deviceAssignDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync devices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceSync(options?: any): AxiosPromise<void> {
            return localVarFp.deviceSync(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get device total counts in device categories.
         * @param {boolean} [onlyUnpaired] If true, fillters paired devices from count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceTotalCounts(onlyUnpaired?: boolean, options?: any): AxiosPromise<Array<DeviceCount>> {
            return localVarFp.deviceTotalCounts(onlyUnpaired, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dismount device.
         * @param {number} deviceId Device id.
         * @param {DismountDto} [dismountDto] Dismount dto.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismount(deviceId: number, dismountDto?: DismountDto, options?: any): AxiosPromise<void> {
            return localVarFp.dismount(deviceId, dismountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Favourize device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favourizeDevice(deviceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.favourizeDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get device by id.
         * @param {number} id Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById(id: number, options?: any): AxiosPromise<DeviceDetailsDto> {
            return localVarFp.getDeviceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filterd paginated device list.
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {number} [id] 
         * @param {boolean} [isActive] 
         * @param {string} [productionNumber] 
         * @param {string} [contractNumber] 
         * @param {string} [customerName] 
         * @param {number} [customerId] 
         * @param {string} [address] 
         * @param {Array<DeviceCategories>} [deviceCategory] 
         * @param {string} [name] 
         * @param {boolean} [isPaired] 
         * @param {boolean} [notInUse] 
         * @param {Array<string>} [dismountDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, id?: number, isActive?: boolean, productionNumber?: string, contractNumber?: string, customerName?: string, customerId?: number, address?: string, deviceCategory?: Array<DeviceCategories>, name?: string, isPaired?: boolean, notInUse?: boolean, dismountDate?: Array<string>, options?: any): AxiosPromise<DeviceListDtoPaginatedList> {
            return localVarFp.getDevices(skip, take, orderBy, orderByAsc, id, isActive, productionNumber, contractNumber, customerName, customerId, address, deviceCategory, name, isPaired, notInUse, dismountDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get devices for contract.
         * @param {number} contractId Contract id.
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {boolean} [isActive] 
         * @param {string} [productionNumber] 
         * @param {DeviceCategories} [deviceCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesForContract(contractId: number, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, isActive?: boolean, productionNumber?: string, deviceCategory?: DeviceCategories, options?: any): AxiosPromise<ContractDeviceListDtoPaginatedList> {
            return localVarFp.getDevicesForContract(contractId, skip, take, orderBy, orderByAsc, isActive, productionNumber, deviceCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get measurement data for device.
         * @param {number} id Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementData(id: number, options?: any): AxiosPromise<MeasurementDataDto> {
            return localVarFp.getMeasurementData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink contrct from device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignContract(deviceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.unassignContract(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unfavourize device.
         * @param {number} deviceId Device id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfavourizeDevice(deviceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.unfavourizeDevice(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update device name.
         * @param {number} deviceId Device id.
         * @param {DeviceNameDto} [deviceNameDto] Device new name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceName(deviceId: number, deviceNameDto?: DeviceNameDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateDeviceName(deviceId, deviceNameDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignDeviceToContract operation in DeviceApi.
 * @export
 * @interface DeviceApiAssignDeviceToContractRequest
 */
export interface DeviceApiAssignDeviceToContractRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiAssignDeviceToContract
     */
    readonly deviceId: number

    /**
     * Assigned values.
     * @type {DeviceAssignDto}
     * @memberof DeviceApiAssignDeviceToContract
     */
    readonly deviceAssignDto?: DeviceAssignDto
}

/**
 * Request parameters for deviceTotalCounts operation in DeviceApi.
 * @export
 * @interface DeviceApiDeviceTotalCountsRequest
 */
export interface DeviceApiDeviceTotalCountsRequest {
    /**
     * If true, fillters paired devices from count.
     * @type {boolean}
     * @memberof DeviceApiDeviceTotalCounts
     */
    readonly onlyUnpaired?: boolean
}

/**
 * Request parameters for dismount operation in DeviceApi.
 * @export
 * @interface DeviceApiDismountRequest
 */
export interface DeviceApiDismountRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiDismount
     */
    readonly deviceId: number

    /**
     * Dismount dto.
     * @type {DismountDto}
     * @memberof DeviceApiDismount
     */
    readonly dismountDto?: DismountDto
}

/**
 * Request parameters for favourizeDevice operation in DeviceApi.
 * @export
 * @interface DeviceApiFavourizeDeviceRequest
 */
export interface DeviceApiFavourizeDeviceRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiFavourizeDevice
     */
    readonly deviceId: number
}

/**
 * Request parameters for getDeviceById operation in DeviceApi.
 * @export
 * @interface DeviceApiGetDeviceByIdRequest
 */
export interface DeviceApiGetDeviceByIdRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiGetDeviceById
     */
    readonly id: number
}

/**
 * Request parameters for getDevices operation in DeviceApi.
 * @export
 * @interface DeviceApiGetDevicesRequest
 */
export interface DeviceApiGetDevicesRequest {
    /**
     * Skip items for paging.
     * @type {number}
     * @memberof DeviceApiGetDevices
     */
    readonly skip?: number

    /**
     * Take items for paging.
     * @type {number}
     * @memberof DeviceApiGetDevices
     */
    readonly take?: number

    /**
     * Order by column name.
     * @type {string}
     * @memberof DeviceApiGetDevices
     */
    readonly orderBy?: string

    /**
     * Is order by ascending or descending.
     * @type {boolean}
     * @memberof DeviceApiGetDevices
     */
    readonly orderByAsc?: boolean

    /**
     * 
     * @type {number}
     * @memberof DeviceApiGetDevices
     */
    readonly id?: number

    /**
     * 
     * @type {boolean}
     * @memberof DeviceApiGetDevices
     */
    readonly isActive?: boolean

    /**
     * 
     * @type {string}
     * @memberof DeviceApiGetDevices
     */
    readonly productionNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DeviceApiGetDevices
     */
    readonly contractNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DeviceApiGetDevices
     */
    readonly customerName?: string

    /**
     * 
     * @type {number}
     * @memberof DeviceApiGetDevices
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof DeviceApiGetDevices
     */
    readonly address?: string

    /**
     * 
     * @type {Array<DeviceCategories>}
     * @memberof DeviceApiGetDevices
     */
    readonly deviceCategory?: Array<DeviceCategories>

    /**
     * 
     * @type {string}
     * @memberof DeviceApiGetDevices
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof DeviceApiGetDevices
     */
    readonly isPaired?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DeviceApiGetDevices
     */
    readonly notInUse?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceApiGetDevices
     */
    readonly dismountDate?: Array<string>
}

/**
 * Request parameters for getDevicesForContract operation in DeviceApi.
 * @export
 * @interface DeviceApiGetDevicesForContractRequest
 */
export interface DeviceApiGetDevicesForContractRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly contractId: number

    /**
     * Skip items for paging.
     * @type {number}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly skip?: number

    /**
     * Take items for paging.
     * @type {number}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly take?: number

    /**
     * Order by column name.
     * @type {string}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly orderBy?: string

    /**
     * Is order by ascending or descending.
     * @type {boolean}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly orderByAsc?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly isActive?: boolean

    /**
     * 
     * @type {string}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly productionNumber?: string

    /**
     * 
     * @type {DeviceCategories}
     * @memberof DeviceApiGetDevicesForContract
     */
    readonly deviceCategory?: DeviceCategories
}

/**
 * Request parameters for getMeasurementData operation in DeviceApi.
 * @export
 * @interface DeviceApiGetMeasurementDataRequest
 */
export interface DeviceApiGetMeasurementDataRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiGetMeasurementData
     */
    readonly id: number
}

/**
 * Request parameters for unassignContract operation in DeviceApi.
 * @export
 * @interface DeviceApiUnassignContractRequest
 */
export interface DeviceApiUnassignContractRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiUnassignContract
     */
    readonly deviceId: number
}

/**
 * Request parameters for unfavourizeDevice operation in DeviceApi.
 * @export
 * @interface DeviceApiUnfavourizeDeviceRequest
 */
export interface DeviceApiUnfavourizeDeviceRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiUnfavourizeDevice
     */
    readonly deviceId: number
}

/**
 * Request parameters for updateDeviceName operation in DeviceApi.
 * @export
 * @interface DeviceApiUpdateDeviceNameRequest
 */
export interface DeviceApiUpdateDeviceNameRequest {
    /**
     * Device id.
     * @type {number}
     * @memberof DeviceApiUpdateDeviceName
     */
    readonly deviceId: number

    /**
     * Device new name.
     * @type {DeviceNameDto}
     * @memberof DeviceApiUpdateDeviceName
     */
    readonly deviceNameDto?: DeviceNameDto
}

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @summary True if there are any unpaired device.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public anyUnpairedDevice(options?: any) {
        return DeviceApiFp(this.configuration).anyUnpairedDevice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign device to contract.
     * @param {DeviceApiAssignDeviceToContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public assignDeviceToContract(requestParameters: DeviceApiAssignDeviceToContractRequest, options?: any) {
        return DeviceApiFp(this.configuration).assignDeviceToContract(requestParameters.deviceId, requestParameters.deviceAssignDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync devices.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceSync(options?: any) {
        return DeviceApiFp(this.configuration).deviceSync(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get device total counts in device categories.
     * @param {DeviceApiDeviceTotalCountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceTotalCounts(requestParameters: DeviceApiDeviceTotalCountsRequest = {}, options?: any) {
        return DeviceApiFp(this.configuration).deviceTotalCounts(requestParameters.onlyUnpaired, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dismount device.
     * @param {DeviceApiDismountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public dismount(requestParameters: DeviceApiDismountRequest, options?: any) {
        return DeviceApiFp(this.configuration).dismount(requestParameters.deviceId, requestParameters.dismountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Favourize device.
     * @param {DeviceApiFavourizeDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public favourizeDevice(requestParameters: DeviceApiFavourizeDeviceRequest, options?: any) {
        return DeviceApiFp(this.configuration).favourizeDevice(requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get device by id.
     * @param {DeviceApiGetDeviceByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceById(requestParameters: DeviceApiGetDeviceByIdRequest, options?: any) {
        return DeviceApiFp(this.configuration).getDeviceById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filterd paginated device list.
     * @param {DeviceApiGetDevicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDevices(requestParameters: DeviceApiGetDevicesRequest = {}, options?: any) {
        return DeviceApiFp(this.configuration).getDevices(requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, requestParameters.id, requestParameters.isActive, requestParameters.productionNumber, requestParameters.contractNumber, requestParameters.customerName, requestParameters.customerId, requestParameters.address, requestParameters.deviceCategory, requestParameters.name, requestParameters.isPaired, requestParameters.notInUse, requestParameters.dismountDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get devices for contract.
     * @param {DeviceApiGetDevicesForContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDevicesForContract(requestParameters: DeviceApiGetDevicesForContractRequest, options?: any) {
        return DeviceApiFp(this.configuration).getDevicesForContract(requestParameters.contractId, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, requestParameters.isActive, requestParameters.productionNumber, requestParameters.deviceCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get measurement data for device.
     * @param {DeviceApiGetMeasurementDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getMeasurementData(requestParameters: DeviceApiGetMeasurementDataRequest, options?: any) {
        return DeviceApiFp(this.configuration).getMeasurementData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink contrct from device.
     * @param {DeviceApiUnassignContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public unassignContract(requestParameters: DeviceApiUnassignContractRequest, options?: any) {
        return DeviceApiFp(this.configuration).unassignContract(requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unfavourize device.
     * @param {DeviceApiUnfavourizeDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public unfavourizeDevice(requestParameters: DeviceApiUnfavourizeDeviceRequest, options?: any) {
        return DeviceApiFp(this.configuration).unfavourizeDevice(requestParameters.deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update device name.
     * @param {DeviceApiUpdateDeviceNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public updateDeviceName(requestParameters: DeviceApiUpdateDeviceNameRequest, options?: any) {
        return DeviceApiFp(this.configuration).updateDeviceName(requestParameters.deviceId, requestParameters.deviceNameDto, options).then((request) => request(this.axios, this.basePath));
    }
}
