import { createModel } from '@rematch/core'
import type { RootModel } from '.'

export const errors = createModel<RootModel>()({
  state: {
    list: [] as any[]
  },
  reducers: {
    add(state, payload: any) {
      state.list.push(payload)
    },
    reset(state) {
      state.list = []
    }
  }
})
