import { initReactI18next } from 'react-i18next'
import 'dayjs/locale/hu'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import i18n from 'i18next'
import en from './locales/en.json'
import hu from './locales/hu.json'

const tz = (import.meta.env.VITE_TIMEZONE as string) || 'Europe/Budapest'
const locale = (import.meta.env.VITE_DEFAULT_LOCALE as string) || 'hu'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.locale(locale)
dayjs.tz.setDefault(tz)

const resources = {
  en: {
    translation: en
  },
  hu: {
    translation: hu
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'hu',
  fallbackLng: 'hu',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
