/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractDeviceSummaryDto } from '../models';
// @ts-ignore
import { ContractDto } from '../models';
// @ts-ignore
import { ContractListDtoPaginatedList } from '../models';
// @ts-ignore
import { ContractNotificationSettingsDto } from '../models';
// @ts-ignore
import { ContractTotalCounts } from '../models';
// @ts-ignore
import { CreateContractDto } from '../models';
// @ts-ignore
import { CreateContractInvoiceDto } from '../models';
// @ts-ignore
import { CreateContractInvoiceResponseDto } from '../models';
// @ts-ignore
import { CustomerDeviceCardDto } from '../models';
// @ts-ignore
import { CustomerTypes } from '../models';
// @ts-ignore
import { DeviceCategories } from '../models';
// @ts-ignore
import { DeviceHistoryDtoPaginatedList } from '../models';
// @ts-ignore
import { IdResponseDto } from '../models';
// @ts-ignore
import { UpdateContractDto } from '../models';
// @ts-ignore
import { UpdateContractNotificationSettingsDto } from '../models';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create contract.
         * @param {CreateContractDto} [createContractDto] Contract to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract: async (createContractDto?: CreateContractDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create invoice for contract.
         * @param {number} contractId Contract id.
         * @param {CreateContractInvoiceDto} [createContractInvoiceDto] Parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: async (contractId: number, createContractInvoiceDto?: CreateContractInvoiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('createInvoice', 'contractId', contractId)
            const localVarPath = `/api/Contract/{contractId}/Invoice`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractInvoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract by id.
         * @param {number} id Id of the contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContractById', 'id', id)
            const localVarPath = `/api/Contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns customer\'s contract device card data.
         * @param {number} id Contract id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractCustomerDevices: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContractCustomerDevices', 'id', id)
            const localVarPath = `/api/Contract/{id}/Device`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns customer\'s contract device summary.
         * @param {number} id Contract id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractCustomerSummary: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContractCustomerSummary', 'id', id)
            const localVarPath = `/api/Contract/{id}/Summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract notification settings.
         * @param {number} id Contrcat id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractNotificationSetting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContractNotificationSetting', 'id', id)
            const localVarPath = `/api/Contract/{id}/NotificationSettings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contract total counts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractTotalCounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contract/TotalCounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filtered and paginated contract list.
         * @param {string} [customerName] 
         * @param {string} [contractNumber] 
         * @param {Array<string>} [contractDate] 
         * @param {string} [address] 
         * @param {Array<string>} [expirationDate] 
         * @param {string} [deviceProductNumber] 
         * @param {boolean} [isActive] 
         * @param {number} [customerId] 
         * @param {CustomerTypes} [customerType] 
         * @param {number} [activeDeviceCount] 
         * @param {string} [billingName] 
         * @param {string} [billingAddress] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts: async (customerName?: string, contractNumber?: string, contractDate?: Array<string>, address?: string, expirationDate?: Array<string>, deviceProductNumber?: string, isActive?: boolean, customerId?: number, customerType?: CustomerTypes, activeDeviceCount?: number, billingName?: string, billingAddress?: string, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (customerName !== undefined) {
                localVarQueryParameter['CustomerName'] = customerName;
            }

            if (contractNumber !== undefined) {
                localVarQueryParameter['ContractNumber'] = contractNumber;
            }

            if (contractDate) {
                localVarQueryParameter['ContractDate'] = contractDate;
            }

            if (address !== undefined) {
                localVarQueryParameter['Address'] = address;
            }

            if (expirationDate) {
                localVarQueryParameter['ExpirationDate'] = expirationDate;
            }

            if (deviceProductNumber !== undefined) {
                localVarQueryParameter['DeviceProductNumber'] = deviceProductNumber;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (customerType !== undefined) {
                localVarQueryParameter['CustomerType'] = customerType;
            }

            if (activeDeviceCount !== undefined) {
                localVarQueryParameter['ActiveDeviceCount'] = activeDeviceCount;
            }

            if (billingName !== undefined) {
                localVarQueryParameter['BillingName'] = billingName;
            }

            if (billingAddress !== undefined) {
                localVarQueryParameter['BillingAddress'] = billingAddress;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filtered paginated device history list.
         * @param {number} id Contract id.
         * @param {DeviceCategories} [deviceCategory] 
         * @param {Array<string>} [received] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceHistory: async (id: number, deviceCategory?: DeviceCategories, received?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceHistory', 'id', id)
            const localVarPath = `/api/Contract/{id}/DeviceHistory`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (deviceCategory !== undefined) {
                localVarQueryParameter['DeviceCategory'] = deviceCategory;
            }

            if (received) {
                localVarQueryParameter['Received'] = received;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update contract.
         * @param {number} id Record id.
         * @param {UpdateContractDto} [updateContractDto] Contract for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContract: async (id: number, updateContractDto?: UpdateContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContract', 'id', id)
            const localVarPath = `/api/Contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification settings.
         * @param {number} id Record Id.
         * @param {UpdateContractNotificationSettingsDto} [updateContractNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractNotificationSetting: async (id: number, updateContractNotificationSettingsDto?: UpdateContractNotificationSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContractNotificationSetting', 'id', id)
            const localVarPath = `/api/Contract/{id}/NotificationSettings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContractNotificationSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create contract.
         * @param {CreateContractDto} [createContractDto] Contract to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContract(createContractDto?: CreateContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContract(createContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create invoice for contract.
         * @param {number} contractId Contract id.
         * @param {CreateContractInvoiceDto} [createContractInvoiceDto] Parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoice(contractId: number, createContractInvoiceDto?: CreateContractInvoiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateContractInvoiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoice(contractId, createContractInvoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract by id.
         * @param {number} id Id of the contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns customer\'s contract device card data.
         * @param {number} id Contract id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractCustomerDevices(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDeviceCardDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractCustomerDevices(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns customer\'s contract device summary.
         * @param {number} id Contract id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractCustomerSummary(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractDeviceSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractCustomerSummary(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract notification settings.
         * @param {number} id Contrcat id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractNotificationSetting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractNotificationSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractNotificationSetting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contract total counts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractTotalCounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractTotalCounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractTotalCounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filtered and paginated contract list.
         * @param {string} [customerName] 
         * @param {string} [contractNumber] 
         * @param {Array<string>} [contractDate] 
         * @param {string} [address] 
         * @param {Array<string>} [expirationDate] 
         * @param {string} [deviceProductNumber] 
         * @param {boolean} [isActive] 
         * @param {number} [customerId] 
         * @param {CustomerTypes} [customerType] 
         * @param {number} [activeDeviceCount] 
         * @param {string} [billingName] 
         * @param {string} [billingAddress] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContracts(customerName?: string, contractNumber?: string, contractDate?: Array<string>, address?: string, expirationDate?: Array<string>, deviceProductNumber?: string, isActive?: boolean, customerId?: number, customerType?: CustomerTypes, activeDeviceCount?: number, billingName?: string, billingAddress?: string, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractListDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContracts(customerName, contractNumber, contractDate, address, expirationDate, deviceProductNumber, isActive, customerId, customerType, activeDeviceCount, billingName, billingAddress, skip, take, orderBy, orderByAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filtered paginated device history list.
         * @param {number} id Contract id.
         * @param {DeviceCategories} [deviceCategory] 
         * @param {Array<string>} [received] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceHistory(id: number, deviceCategory?: DeviceCategories, received?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceHistoryDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceHistory(id, deviceCategory, received, skip, take, orderBy, orderByAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update contract.
         * @param {number} id Record id.
         * @param {UpdateContractDto} [updateContractDto] Contract for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContract(id: number, updateContractDto?: UpdateContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContract(id, updateContractDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update notification settings.
         * @param {number} id Record Id.
         * @param {UpdateContractNotificationSettingsDto} [updateContractNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContractNotificationSetting(id: number, updateContractNotificationSettingsDto?: UpdateContractNotificationSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContractNotificationSetting(id, updateContractNotificationSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Create contract.
         * @param {CreateContractDto} [createContractDto] Contract to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract(createContractDto?: CreateContractDto, options?: any): AxiosPromise<IdResponseDto> {
            return localVarFp.createContract(createContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create invoice for contract.
         * @param {number} contractId Contract id.
         * @param {CreateContractInvoiceDto} [createContractInvoiceDto] Parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice(contractId: number, createContractInvoiceDto?: CreateContractInvoiceDto, options?: any): AxiosPromise<CreateContractInvoiceResponseDto> {
            return localVarFp.createInvoice(contractId, createContractInvoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract by id.
         * @param {number} id Id of the contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(id: number, options?: any): AxiosPromise<ContractDto> {
            return localVarFp.getContractById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns customer\'s contract device card data.
         * @param {number} id Contract id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractCustomerDevices(id: number, options?: any): AxiosPromise<Array<CustomerDeviceCardDto>> {
            return localVarFp.getContractCustomerDevices(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns customer\'s contract device summary.
         * @param {number} id Contract id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractCustomerSummary(id: number, options?: any): AxiosPromise<ContractDeviceSummaryDto> {
            return localVarFp.getContractCustomerSummary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract notification settings.
         * @param {number} id Contrcat id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractNotificationSetting(id: number, options?: any): AxiosPromise<ContractNotificationSettingsDto> {
            return localVarFp.getContractNotificationSetting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contract total counts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractTotalCounts(options?: any): AxiosPromise<ContractTotalCounts> {
            return localVarFp.getContractTotalCounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filtered and paginated contract list.
         * @param {string} [customerName] 
         * @param {string} [contractNumber] 
         * @param {Array<string>} [contractDate] 
         * @param {string} [address] 
         * @param {Array<string>} [expirationDate] 
         * @param {string} [deviceProductNumber] 
         * @param {boolean} [isActive] 
         * @param {number} [customerId] 
         * @param {CustomerTypes} [customerType] 
         * @param {number} [activeDeviceCount] 
         * @param {string} [billingName] 
         * @param {string} [billingAddress] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(customerName?: string, contractNumber?: string, contractDate?: Array<string>, address?: string, expirationDate?: Array<string>, deviceProductNumber?: string, isActive?: boolean, customerId?: number, customerType?: CustomerTypes, activeDeviceCount?: number, billingName?: string, billingAddress?: string, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): AxiosPromise<ContractListDtoPaginatedList> {
            return localVarFp.getContracts(customerName, contractNumber, contractDate, address, expirationDate, deviceProductNumber, isActive, customerId, customerType, activeDeviceCount, billingName, billingAddress, skip, take, orderBy, orderByAsc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filtered paginated device history list.
         * @param {number} id Contract id.
         * @param {DeviceCategories} [deviceCategory] 
         * @param {Array<string>} [received] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceHistory(id: number, deviceCategory?: DeviceCategories, received?: Array<string>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): AxiosPromise<DeviceHistoryDtoPaginatedList> {
            return localVarFp.getDeviceHistory(id, deviceCategory, received, skip, take, orderBy, orderByAsc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update contract.
         * @param {number} id Record id.
         * @param {UpdateContractDto} [updateContractDto] Contract for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContract(id: number, updateContractDto?: UpdateContractDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateContract(id, updateContractDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification settings.
         * @param {number} id Record Id.
         * @param {UpdateContractNotificationSettingsDto} [updateContractNotificationSettingsDto] Notification settings for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContractNotificationSetting(id: number, updateContractNotificationSettingsDto?: UpdateContractNotificationSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateContractNotificationSetting(id, updateContractNotificationSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createContract operation in ContractApi.
 * @export
 * @interface ContractApiCreateContractRequest
 */
export interface ContractApiCreateContractRequest {
    /**
     * Contract to create.
     * @type {CreateContractDto}
     * @memberof ContractApiCreateContract
     */
    readonly createContractDto?: CreateContractDto
}

/**
 * Request parameters for createInvoice operation in ContractApi.
 * @export
 * @interface ContractApiCreateInvoiceRequest
 */
export interface ContractApiCreateInvoiceRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof ContractApiCreateInvoice
     */
    readonly contractId: number

    /**
     * Parameters.
     * @type {CreateContractInvoiceDto}
     * @memberof ContractApiCreateInvoice
     */
    readonly createContractInvoiceDto?: CreateContractInvoiceDto
}

/**
 * Request parameters for getContractById operation in ContractApi.
 * @export
 * @interface ContractApiGetContractByIdRequest
 */
export interface ContractApiGetContractByIdRequest {
    /**
     * Id of the contract.
     * @type {number}
     * @memberof ContractApiGetContractById
     */
    readonly id: number
}

/**
 * Request parameters for getContractCustomerDevices operation in ContractApi.
 * @export
 * @interface ContractApiGetContractCustomerDevicesRequest
 */
export interface ContractApiGetContractCustomerDevicesRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof ContractApiGetContractCustomerDevices
     */
    readonly id: number
}

/**
 * Request parameters for getContractCustomerSummary operation in ContractApi.
 * @export
 * @interface ContractApiGetContractCustomerSummaryRequest
 */
export interface ContractApiGetContractCustomerSummaryRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof ContractApiGetContractCustomerSummary
     */
    readonly id: number
}

/**
 * Request parameters for getContractNotificationSetting operation in ContractApi.
 * @export
 * @interface ContractApiGetContractNotificationSettingRequest
 */
export interface ContractApiGetContractNotificationSettingRequest {
    /**
     * Contrcat id.
     * @type {number}
     * @memberof ContractApiGetContractNotificationSetting
     */
    readonly id: number
}

/**
 * Request parameters for getContracts operation in ContractApi.
 * @export
 * @interface ContractApiGetContractsRequest
 */
export interface ContractApiGetContractsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractApiGetContracts
     */
    readonly customerName?: string

    /**
     * 
     * @type {string}
     * @memberof ContractApiGetContracts
     */
    readonly contractNumber?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContractApiGetContracts
     */
    readonly contractDate?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ContractApiGetContracts
     */
    readonly address?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContractApiGetContracts
     */
    readonly expirationDate?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ContractApiGetContracts
     */
    readonly deviceProductNumber?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContractApiGetContracts
     */
    readonly isActive?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContractApiGetContracts
     */
    readonly customerId?: number

    /**
     * 
     * @type {CustomerTypes}
     * @memberof ContractApiGetContracts
     */
    readonly customerType?: CustomerTypes

    /**
     * 
     * @type {number}
     * @memberof ContractApiGetContracts
     */
    readonly activeDeviceCount?: number

    /**
     * 
     * @type {string}
     * @memberof ContractApiGetContracts
     */
    readonly billingName?: string

    /**
     * 
     * @type {string}
     * @memberof ContractApiGetContracts
     */
    readonly billingAddress?: string

    /**
     * Skip items for paging.
     * @type {number}
     * @memberof ContractApiGetContracts
     */
    readonly skip?: number

    /**
     * Take items for paging.
     * @type {number}
     * @memberof ContractApiGetContracts
     */
    readonly take?: number

    /**
     * Order by column name.
     * @type {string}
     * @memberof ContractApiGetContracts
     */
    readonly orderBy?: string

    /**
     * Is order by ascending or descending.
     * @type {boolean}
     * @memberof ContractApiGetContracts
     */
    readonly orderByAsc?: boolean
}

/**
 * Request parameters for getDeviceHistory operation in ContractApi.
 * @export
 * @interface ContractApiGetDeviceHistoryRequest
 */
export interface ContractApiGetDeviceHistoryRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof ContractApiGetDeviceHistory
     */
    readonly id: number

    /**
     * 
     * @type {DeviceCategories}
     * @memberof ContractApiGetDeviceHistory
     */
    readonly deviceCategory?: DeviceCategories

    /**
     * 
     * @type {Array<string>}
     * @memberof ContractApiGetDeviceHistory
     */
    readonly received?: Array<string>

    /**
     * Skip items for paging.
     * @type {number}
     * @memberof ContractApiGetDeviceHistory
     */
    readonly skip?: number

    /**
     * Take items for paging.
     * @type {number}
     * @memberof ContractApiGetDeviceHistory
     */
    readonly take?: number

    /**
     * Order by column name.
     * @type {string}
     * @memberof ContractApiGetDeviceHistory
     */
    readonly orderBy?: string

    /**
     * Is order by ascending or descending.
     * @type {boolean}
     * @memberof ContractApiGetDeviceHistory
     */
    readonly orderByAsc?: boolean
}

/**
 * Request parameters for updateContract operation in ContractApi.
 * @export
 * @interface ContractApiUpdateContractRequest
 */
export interface ContractApiUpdateContractRequest {
    /**
     * Record id.
     * @type {number}
     * @memberof ContractApiUpdateContract
     */
    readonly id: number

    /**
     * Contract for update.
     * @type {UpdateContractDto}
     * @memberof ContractApiUpdateContract
     */
    readonly updateContractDto?: UpdateContractDto
}

/**
 * Request parameters for updateContractNotificationSetting operation in ContractApi.
 * @export
 * @interface ContractApiUpdateContractNotificationSettingRequest
 */
export interface ContractApiUpdateContractNotificationSettingRequest {
    /**
     * Record Id.
     * @type {number}
     * @memberof ContractApiUpdateContractNotificationSetting
     */
    readonly id: number

    /**
     * Notification settings for update.
     * @type {UpdateContractNotificationSettingsDto}
     * @memberof ContractApiUpdateContractNotificationSetting
     */
    readonly updateContractNotificationSettingsDto?: UpdateContractNotificationSettingsDto
}

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary Create contract.
     * @param {ContractApiCreateContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public createContract(requestParameters: ContractApiCreateContractRequest = {}, options?: any) {
        return ContractApiFp(this.configuration).createContract(requestParameters.createContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create invoice for contract.
     * @param {ContractApiCreateInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public createInvoice(requestParameters: ContractApiCreateInvoiceRequest, options?: any) {
        return ContractApiFp(this.configuration).createInvoice(requestParameters.contractId, requestParameters.createContractInvoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract by id.
     * @param {ContractApiGetContractByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractById(requestParameters: ContractApiGetContractByIdRequest, options?: any) {
        return ContractApiFp(this.configuration).getContractById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns customer\'s contract device card data.
     * @param {ContractApiGetContractCustomerDevicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractCustomerDevices(requestParameters: ContractApiGetContractCustomerDevicesRequest, options?: any) {
        return ContractApiFp(this.configuration).getContractCustomerDevices(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns customer\'s contract device summary.
     * @param {ContractApiGetContractCustomerSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractCustomerSummary(requestParameters: ContractApiGetContractCustomerSummaryRequest, options?: any) {
        return ContractApiFp(this.configuration).getContractCustomerSummary(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract notification settings.
     * @param {ContractApiGetContractNotificationSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractNotificationSetting(requestParameters: ContractApiGetContractNotificationSettingRequest, options?: any) {
        return ContractApiFp(this.configuration).getContractNotificationSetting(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contract total counts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContractTotalCounts(options?: any) {
        return ContractApiFp(this.configuration).getContractTotalCounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filtered and paginated contract list.
     * @param {ContractApiGetContractsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getContracts(requestParameters: ContractApiGetContractsRequest = {}, options?: any) {
        return ContractApiFp(this.configuration).getContracts(requestParameters.customerName, requestParameters.contractNumber, requestParameters.contractDate, requestParameters.address, requestParameters.expirationDate, requestParameters.deviceProductNumber, requestParameters.isActive, requestParameters.customerId, requestParameters.customerType, requestParameters.activeDeviceCount, requestParameters.billingName, requestParameters.billingAddress, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filtered paginated device history list.
     * @param {ContractApiGetDeviceHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public getDeviceHistory(requestParameters: ContractApiGetDeviceHistoryRequest, options?: any) {
        return ContractApiFp(this.configuration).getDeviceHistory(requestParameters.id, requestParameters.deviceCategory, requestParameters.received, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update contract.
     * @param {ContractApiUpdateContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public updateContract(requestParameters: ContractApiUpdateContractRequest, options?: any) {
        return ContractApiFp(this.configuration).updateContract(requestParameters.id, requestParameters.updateContractDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update notification settings.
     * @param {ContractApiUpdateContractNotificationSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public updateContractNotificationSetting(requestParameters: ContractApiUpdateContractNotificationSettingRequest, options?: any) {
        return ContractApiFp(this.configuration).updateContractNotificationSetting(requestParameters.id, requestParameters.updateContractNotificationSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}
