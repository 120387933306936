import { createModel, RematchRootState } from '@rematch/core'
import { api } from '@/api'
import {
  ContractApiCreateContractRequest,
  ContractApiGetContractsRequest,
  ContractApiUpdateContractNotificationSettingRequest,
  ContractApiUpdateContractRequest,
  ContractDeviceSummaryDto,
  ContractDto,
  ContractListDto,
  ContractTotalCounts,
  CustomerDeviceCardDto,
  IdResponseDto
} from '@/api/generated'
import { ContractNotificationSettingsDto } from '@/api/generated/models/contract-notification-settings-dto'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import type { RootModel } from '.'

type ContractState = {
  contracts?: ContractListDto[]
  contractListParams?: ListRequestParams
  totalCounts?: ContractTotalCounts
  contract: ContractDto
  notificationSettings?: ContractNotificationSettingsDto
  deviceSummary?: ContractDeviceSummaryDto
  contractDevices?: CustomerDeviceCardDto[]
}

export const contract = createModel<RootModel>()({
  state: {} as ContractState,
  reducers: {
    reset(state) {
      state.contracts = []
      state.contractListParams = {}
    },
    setContracts(state, payload: { contracts: ContractListDto[]; listParams: ListRequestParams }) {
      state.contracts = payload.contracts
      state.contractListParams = payload.listParams
    },
    setTotalCounts(state, totalCounts: ContractTotalCounts) {
      state.totalCounts = totalCounts
    },
    setContract(state, contract: ContractDto) {
      state.contract = contract
    },
    setNotificationSettings(state, notificationSettings: ContractNotificationSettingsDto) {
      state.notificationSettings = notificationSettings
    },
    setDeviceSummary(state, summary: ContractDeviceSummaryDto) {
      state.deviceSummary = summary
    },
    setContractDevices(state, devices: CustomerDeviceCardDto[]) {
      state.contractDevices = devices
    }
  },
  effects: dispatch => {
    const { contract } = dispatch

    return {
      async getContracts(
        params: ContractApiGetContractsRequest = {},
        state: RematchRootState<RootModel>
      ): Promise<void> {
        const requestParams = { ...state.contract.contractListParams, ...params }

        const {
          data: { result, ...listParams }
        } = await api.contract.getContracts(requestParams)
        contract.setContracts({ contracts: result || [], listParams })
      },

      async getContractsWithOrderByIsActive(
        params: ContractApiGetContractsRequest = {},
        state: RematchRootState<RootModel>
      ): Promise<void> {
        const requestPayload = !params.orderBy
          ? {
              ...params,
              orderBy: 'isActive',
              orderByAsc: false
            }
          : params

        const requestParams = {
          ...state.contract.contractListParams,
          ...requestPayload
        }

        const {
          data: { result, ...listParams }
        } = await api.contract.getContracts(requestParams)
        contract.setContracts({ contracts: result || [], listParams })
      },

      async getTotalCounts(): Promise<void> {
        const { data } = await api.contract.getContractTotalCounts()
        contract.setTotalCounts(data)
      },

      async getContract(id: number): Promise<ContractDto> {
        const { data } = await api.contract.getContractById({ id })
        contract.setContract(data)

        return data
      },

      async getNotificationSettings(id: number): Promise<void> {
        const { data } = await api.contract.getContractNotificationSetting({ id })
        contract.setNotificationSettings(data)
      },

      async updateNotificationSetting({
        id,
        updateContractNotificationSettingsDto
      }: ContractApiUpdateContractNotificationSettingRequest): Promise<void> {
        await api.contract.updateContractNotificationSetting({
          id,
          updateContractNotificationSettingsDto
        })
      },

      async createContract({ createContractDto }: ContractApiCreateContractRequest): Promise<IdResponseDto> {
        const { data } = await api.contract.createContract({ createContractDto })
        return data
      },

      async updateContract({ id, updateContractDto }: ContractApiUpdateContractRequest): Promise<void> {
        await api.contract.updateContract({ id, updateContractDto })
      },

      async getDeviceSummary(id: number): Promise<void> {
        const { data } = await api.contract.getContractCustomerSummary({ id })
        contract.setDeviceSummary(data)
      },

      async getContractDevices(id: number): Promise<void> {
        const { data } = await api.contract.getContractCustomerDevices({ id })
        contract.setContractDevices(data)
      }
    }
  }
})
