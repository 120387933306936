/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RefreshTokenDto } from '../models';
// @ts-ignore
import { RequestPasswordTokenResultDto } from '../models';
// @ts-ignore
import { RequestRefreshTokenResultDto } from '../models';
// @ts-ignore
import { RevokeRefreshTokenCommand } from '../models';
// @ts-ignore
import { SignInDto } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary User login.
         * @param {SignInDto} [signInDto] Login datas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (signInDto?: SignInDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout. Revokes refreshtoken.
         * @param {RevokeRefreshTokenCommand} [revokeRefreshTokenCommand] Refresh token to revoke.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (revokeRefreshTokenCommand?: RevokeRefreshTokenCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeRefreshTokenCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh jwt token.
         * @param {RefreshTokenDto} [refreshTokenDto] Input for refresh token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshTokenDto?: RefreshTokenDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/RefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary User login.
         * @param {SignInDto} [signInDto] Login datas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(signInDto?: SignInDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPasswordTokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(signInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout. Revokes refreshtoken.
         * @param {RevokeRefreshTokenCommand} [revokeRefreshTokenCommand] Refresh token to revoke.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(revokeRefreshTokenCommand?: RevokeRefreshTokenCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(revokeRefreshTokenCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh jwt token.
         * @param {RefreshTokenDto} [refreshTokenDto] Input for refresh token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshTokenDto?: RefreshTokenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestRefreshTokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary User login.
         * @param {SignInDto} [signInDto] Login datas.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(signInDto?: SignInDto, options?: any): AxiosPromise<RequestPasswordTokenResultDto> {
            return localVarFp.login(signInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout. Revokes refreshtoken.
         * @param {RevokeRefreshTokenCommand} [revokeRefreshTokenCommand] Refresh token to revoke.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(revokeRefreshTokenCommand?: RevokeRefreshTokenCommand, options?: any): AxiosPromise<void> {
            return localVarFp.logout(revokeRefreshTokenCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh jwt token.
         * @param {RefreshTokenDto} [refreshTokenDto] Input for refresh token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshTokenDto?: RefreshTokenDto, options?: any): AxiosPromise<RequestRefreshTokenResultDto> {
            return localVarFp.refreshToken(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * Login datas.
     * @type {SignInDto}
     * @memberof AuthApiLogin
     */
    readonly signInDto?: SignInDto
}

/**
 * Request parameters for logout operation in AuthApi.
 * @export
 * @interface AuthApiLogoutRequest
 */
export interface AuthApiLogoutRequest {
    /**
     * Refresh token to revoke.
     * @type {RevokeRefreshTokenCommand}
     * @memberof AuthApiLogout
     */
    readonly revokeRefreshTokenCommand?: RevokeRefreshTokenCommand
}

/**
 * Request parameters for refreshToken operation in AuthApi.
 * @export
 * @interface AuthApiRefreshTokenRequest
 */
export interface AuthApiRefreshTokenRequest {
    /**
     * Input for refresh token.
     * @type {RefreshTokenDto}
     * @memberof AuthApiRefreshToken
     */
    readonly refreshTokenDto?: RefreshTokenDto
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary User login.
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).login(requestParameters.signInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout. Revokes refreshtoken.
     * @param {AuthApiLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(requestParameters: AuthApiLogoutRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).logout(requestParameters.revokeRefreshTokenCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh jwt token.
     * @param {AuthApiRefreshTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(requestParameters: AuthApiRefreshTokenRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).refreshToken(requestParameters.refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}
