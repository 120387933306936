/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddContactsDto } from '../models';
// @ts-ignore
import { ContactDto } from '../models';
// @ts-ignore
import { InviteContactsDto } from '../models';
// @ts-ignore
import { RemoveContactsDto } from '../models';
/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add contacts to contract.
         * @param {number} contractId Contract id.
         * @param {AddContactsDto} [addContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactsToContract: async (contractId: number, addContactsDto?: AddContactsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('addContactsToContract', 'contractId', contractId)
            const localVarPath = `/api/Contact/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addContactsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {AddContactsDto} [addContactsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactsToCustomer: async (customerId: number, addContactsDto?: AddContactsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('addContactsToCustomer', 'customerId', customerId)
            const localVarPath = `/api/Contact/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addContactsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contacts for contract.
         * @param {number} contractId Contract id.
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractContacts: async (contractId: number, archived?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getContractContacts', 'contractId', contractId)
            const localVarPath = `/api/Contact/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (archived !== undefined) {
                localVarQueryParameter['Archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contacts for customer.
         * @param {number} customerId Customer id.
         * @param {boolean} [archived] 
         * @param {boolean} [addContractContacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerContacts: async (customerId: number, archived?: boolean, addContractContacts?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerContacts', 'customerId', customerId)
            const localVarPath = `/api/Contact/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (archived !== undefined) {
                localVarQueryParameter['Archived'] = archived;
            }

            if (addContractContacts !== undefined) {
                localVarQueryParameter['AddContractContacts'] = addContractContacts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite contacts for contract.
         * @param {number} contractId Contract id.
         * @param {InviteContactsDto} [inviteContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteContactsForContract: async (contractId: number, inviteContactsDto?: InviteContactsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('inviteContactsForContract', 'contractId', contractId)
            const localVarPath = `/api/Contact/contract/invite/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteContactsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite contacts for customer by email address.
         * @param {number} customerId Customer id.
         * @param {InviteContactsDto} [inviteContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteContactsForCustomer: async (customerId: number, inviteContactsDto?: InviteContactsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('inviteContactsForCustomer', 'customerId', customerId)
            const localVarPath = `/api/Contact/customer/invite/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteContactsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove contacts from customer.
         * @param {number} contractId Customer id.
         * @param {RemoveContactsDto} [removeContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContactsFromContract: async (contractId: number, removeContactsDto?: RemoveContactsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('removeContactsFromContract', 'contractId', contractId)
            const localVarPath = `/api/Contact/contract/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeContactsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove contacts from customer.
         * @param {number} customerId Customer id.
         * @param {RemoveContactsDto} [removeContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContactsFromCustomer: async (customerId: number, removeContactsDto?: RemoveContactsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('removeContactsFromCustomer', 'customerId', customerId)
            const localVarPath = `/api/Contact/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeContactsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add contacts to contract.
         * @param {number} contractId Contract id.
         * @param {AddContactsDto} [addContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContactsToContract(contractId: number, addContactsDto?: AddContactsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContactsToContract(contractId, addContactsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {AddContactsDto} [addContactsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContactsToCustomer(customerId: number, addContactsDto?: AddContactsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContactsToCustomer(customerId, addContactsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contacts for contract.
         * @param {number} contractId Contract id.
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractContacts(contractId: number, archived?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractContacts(contractId, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get contacts for customer.
         * @param {number} customerId Customer id.
         * @param {boolean} [archived] 
         * @param {boolean} [addContractContacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerContacts(customerId: number, archived?: boolean, addContractContacts?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerContacts(customerId, archived, addContractContacts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite contacts for contract.
         * @param {number} contractId Contract id.
         * @param {InviteContactsDto} [inviteContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteContactsForContract(contractId: number, inviteContactsDto?: InviteContactsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteContactsForContract(contractId, inviteContactsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite contacts for customer by email address.
         * @param {number} customerId Customer id.
         * @param {InviteContactsDto} [inviteContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteContactsForCustomer(customerId: number, inviteContactsDto?: InviteContactsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteContactsForCustomer(customerId, inviteContactsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove contacts from customer.
         * @param {number} contractId Customer id.
         * @param {RemoveContactsDto} [removeContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeContactsFromContract(contractId: number, removeContactsDto?: RemoveContactsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeContactsFromContract(contractId, removeContactsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove contacts from customer.
         * @param {number} customerId Customer id.
         * @param {RemoveContactsDto} [removeContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeContactsFromCustomer(customerId: number, removeContactsDto?: RemoveContactsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeContactsFromCustomer(customerId, removeContactsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Add contacts to contract.
         * @param {number} contractId Contract id.
         * @param {AddContactsDto} [addContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactsToContract(contractId: number, addContactsDto?: AddContactsDto, options?: any): AxiosPromise<void> {
            return localVarFp.addContactsToContract(contractId, addContactsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {AddContactsDto} [addContactsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactsToCustomer(customerId: number, addContactsDto?: AddContactsDto, options?: any): AxiosPromise<void> {
            return localVarFp.addContactsToCustomer(customerId, addContactsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contacts for contract.
         * @param {number} contractId Contract id.
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractContacts(contractId: number, archived?: boolean, options?: any): AxiosPromise<Array<ContactDto>> {
            return localVarFp.getContractContacts(contractId, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contacts for customer.
         * @param {number} customerId Customer id.
         * @param {boolean} [archived] 
         * @param {boolean} [addContractContacts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerContacts(customerId: number, archived?: boolean, addContractContacts?: boolean, options?: any): AxiosPromise<Array<ContactDto>> {
            return localVarFp.getCustomerContacts(customerId, archived, addContractContacts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite contacts for contract.
         * @param {number} contractId Contract id.
         * @param {InviteContactsDto} [inviteContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteContactsForContract(contractId: number, inviteContactsDto?: InviteContactsDto, options?: any): AxiosPromise<void> {
            return localVarFp.inviteContactsForContract(contractId, inviteContactsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite contacts for customer by email address.
         * @param {number} customerId Customer id.
         * @param {InviteContactsDto} [inviteContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteContactsForCustomer(customerId: number, inviteContactsDto?: InviteContactsDto, options?: any): AxiosPromise<void> {
            return localVarFp.inviteContactsForCustomer(customerId, inviteContactsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove contacts from customer.
         * @param {number} contractId Customer id.
         * @param {RemoveContactsDto} [removeContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContactsFromContract(contractId: number, removeContactsDto?: RemoveContactsDto, options?: any): AxiosPromise<void> {
            return localVarFp.removeContactsFromContract(contractId, removeContactsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove contacts from customer.
         * @param {number} customerId Customer id.
         * @param {RemoveContactsDto} [removeContactsDto] Contacts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeContactsFromCustomer(customerId: number, removeContactsDto?: RemoveContactsDto, options?: any): AxiosPromise<void> {
            return localVarFp.removeContactsFromCustomer(customerId, removeContactsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addContactsToContract operation in ContactApi.
 * @export
 * @interface ContactApiAddContactsToContractRequest
 */
export interface ContactApiAddContactsToContractRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof ContactApiAddContactsToContract
     */
    readonly contractId: number

    /**
     * Contacts.
     * @type {AddContactsDto}
     * @memberof ContactApiAddContactsToContract
     */
    readonly addContactsDto?: AddContactsDto
}

/**
 * Request parameters for addContactsToCustomer operation in ContactApi.
 * @export
 * @interface ContactApiAddContactsToCustomerRequest
 */
export interface ContactApiAddContactsToCustomerRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactApiAddContactsToCustomer
     */
    readonly customerId: number

    /**
     * 
     * @type {AddContactsDto}
     * @memberof ContactApiAddContactsToCustomer
     */
    readonly addContactsDto?: AddContactsDto
}

/**
 * Request parameters for getContractContacts operation in ContactApi.
 * @export
 * @interface ContactApiGetContractContactsRequest
 */
export interface ContactApiGetContractContactsRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof ContactApiGetContractContacts
     */
    readonly contractId: number

    /**
     * 
     * @type {boolean}
     * @memberof ContactApiGetContractContacts
     */
    readonly archived?: boolean
}

/**
 * Request parameters for getCustomerContacts operation in ContactApi.
 * @export
 * @interface ContactApiGetCustomerContactsRequest
 */
export interface ContactApiGetCustomerContactsRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof ContactApiGetCustomerContacts
     */
    readonly customerId: number

    /**
     * 
     * @type {boolean}
     * @memberof ContactApiGetCustomerContacts
     */
    readonly archived?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContactApiGetCustomerContacts
     */
    readonly addContractContacts?: boolean
}

/**
 * Request parameters for inviteContactsForContract operation in ContactApi.
 * @export
 * @interface ContactApiInviteContactsForContractRequest
 */
export interface ContactApiInviteContactsForContractRequest {
    /**
     * Contract id.
     * @type {number}
     * @memberof ContactApiInviteContactsForContract
     */
    readonly contractId: number

    /**
     * Contacts.
     * @type {InviteContactsDto}
     * @memberof ContactApiInviteContactsForContract
     */
    readonly inviteContactsDto?: InviteContactsDto
}

/**
 * Request parameters for inviteContactsForCustomer operation in ContactApi.
 * @export
 * @interface ContactApiInviteContactsForCustomerRequest
 */
export interface ContactApiInviteContactsForCustomerRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof ContactApiInviteContactsForCustomer
     */
    readonly customerId: number

    /**
     * Contacts.
     * @type {InviteContactsDto}
     * @memberof ContactApiInviteContactsForCustomer
     */
    readonly inviteContactsDto?: InviteContactsDto
}

/**
 * Request parameters for removeContactsFromContract operation in ContactApi.
 * @export
 * @interface ContactApiRemoveContactsFromContractRequest
 */
export interface ContactApiRemoveContactsFromContractRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof ContactApiRemoveContactsFromContract
     */
    readonly contractId: number

    /**
     * Contacts.
     * @type {RemoveContactsDto}
     * @memberof ContactApiRemoveContactsFromContract
     */
    readonly removeContactsDto?: RemoveContactsDto
}

/**
 * Request parameters for removeContactsFromCustomer operation in ContactApi.
 * @export
 * @interface ContactApiRemoveContactsFromCustomerRequest
 */
export interface ContactApiRemoveContactsFromCustomerRequest {
    /**
     * Customer id.
     * @type {number}
     * @memberof ContactApiRemoveContactsFromCustomer
     */
    readonly customerId: number

    /**
     * Contacts.
     * @type {RemoveContactsDto}
     * @memberof ContactApiRemoveContactsFromCustomer
     */
    readonly removeContactsDto?: RemoveContactsDto
}

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @summary Add contacts to contract.
     * @param {ContactApiAddContactsToContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public addContactsToContract(requestParameters: ContactApiAddContactsToContractRequest, options?: any) {
        return ContactApiFp(this.configuration).addContactsToContract(requestParameters.contractId, requestParameters.addContactsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactApiAddContactsToCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public addContactsToCustomer(requestParameters: ContactApiAddContactsToCustomerRequest, options?: any) {
        return ContactApiFp(this.configuration).addContactsToCustomer(requestParameters.customerId, requestParameters.addContactsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contacts for contract.
     * @param {ContactApiGetContractContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public getContractContacts(requestParameters: ContactApiGetContractContactsRequest, options?: any) {
        return ContactApiFp(this.configuration).getContractContacts(requestParameters.contractId, requestParameters.archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contacts for customer.
     * @param {ContactApiGetCustomerContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public getCustomerContacts(requestParameters: ContactApiGetCustomerContactsRequest, options?: any) {
        return ContactApiFp(this.configuration).getCustomerContacts(requestParameters.customerId, requestParameters.archived, requestParameters.addContractContacts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite contacts for contract.
     * @param {ContactApiInviteContactsForContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public inviteContactsForContract(requestParameters: ContactApiInviteContactsForContractRequest, options?: any) {
        return ContactApiFp(this.configuration).inviteContactsForContract(requestParameters.contractId, requestParameters.inviteContactsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite contacts for customer by email address.
     * @param {ContactApiInviteContactsForCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public inviteContactsForCustomer(requestParameters: ContactApiInviteContactsForCustomerRequest, options?: any) {
        return ContactApiFp(this.configuration).inviteContactsForCustomer(requestParameters.customerId, requestParameters.inviteContactsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove contacts from customer.
     * @param {ContactApiRemoveContactsFromContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public removeContactsFromContract(requestParameters: ContactApiRemoveContactsFromContractRequest, options?: any) {
        return ContactApiFp(this.configuration).removeContactsFromContract(requestParameters.contractId, requestParameters.removeContactsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove contacts from customer.
     * @param {ContactApiRemoveContactsFromCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public removeContactsFromCustomer(requestParameters: ContactApiRemoveContactsFromCustomerRequest, options?: any) {
        return ContactApiFp(this.configuration).removeContactsFromCustomer(requestParameters.customerId, requestParameters.removeContactsDto, options).then((request) => request(this.axios, this.basePath));
    }
}
