import { RequestPasswordTokenResultDto } from '@/api/generated'
import { User } from '@/models/user'
import { ENLocalStorage } from './storage'

interface UserService {
  setUser: (props: RequestPasswordTokenResultDto) => User
  getUser: () => User
  removeUser: () => void
  getToken: () => { accessToken?: string; refreshToken?: string }
  updateUserName: ({ firstName, lastName }: Record<'firstName' | 'lastName', string>) => User
}

const parseToken = (token?: string): Nullable<Record<string, any>> => {
  if (!token) {
    return undefined
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const payload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join('')
  )

  return JSON.parse(payload)
}

export default {
  getUser() {
    return ENLocalStorage.get('user')
  },

  setUser({ accessToken, refreshToken, ...rest }: RequestPasswordTokenResultDto) {
    const user = accessToken && parseToken(accessToken)
    ENLocalStorage.set('user', { ...user, ...rest })

    if (accessToken) {
      ENLocalStorage.set('token', accessToken)
    }

    if (refreshToken) {
      ENLocalStorage.set('refresh-token', refreshToken)
    }

    return user
  },

  removeUser() {
    ENLocalStorage.remove(['user', 'token', 'refresh-token'])
  },

  getToken() {
    return {
      accessToken: ENLocalStorage.get('token'),
      refreshToken: ENLocalStorage.get('refresh-token')
    }
  },

  updateUserName({ firstName, lastName }: Record<'firstName' | 'lastName', string>): User {
    const user = this.getUser()
    const updated = { ...user, firstName, lastName }
    ENLocalStorage.set('user', updated)

    return updated
  }
} as UserService
