/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IdResponseDto } from '../models';
// @ts-ignore
import { IrregularDayDto } from '../models';
// @ts-ignore
import { IrregularDayNoIdDto } from '../models';
/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create irregular day.
         * @param {IrregularDayNoIdDto} [irregularDayNoIdDto] Input for add irregular day.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIrregularDay: async (irregularDayNoIdDto?: IrregularDayNoIdDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Calendar/IrregularDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(irregularDayNoIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete irregular day.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIrregularDay: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteIrregularDay', 'id', id)
            const localVarPath = `/api/Calendar/IrregularDay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get IrregularDayDto by id.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIrregularDaysById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIrregularDaysById', 'id', id)
            const localVarPath = `/api/Calendar/IrregularDay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all irregular days for selected year.
         * @param {number} year Year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIrregularDaysByYear: async (year: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getIrregularDaysByYear', 'year', year)
            const localVarPath = `/api/Calendar/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync irregular days for the selected year from google calandar.
         * @param {number} year Year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncIrregularDays: async (year: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('syncIrregularDays', 'year', year)
            const localVarPath = `/api/Calendar/Sync/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update irregular day.
         * @param {number} id Record Id.
         * @param {IrregularDayNoIdDto} [irregularDayNoIdDto] Data to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIrregularDay: async (id: number, irregularDayNoIdDto?: IrregularDayNoIdDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIrregularDay', 'id', id)
            const localVarPath = `/api/Calendar/IrregularDay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(irregularDayNoIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create irregular day.
         * @param {IrregularDayNoIdDto} [irregularDayNoIdDto] Input for add irregular day.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIrregularDay(irregularDayNoIdDto?: IrregularDayNoIdDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIrregularDay(irregularDayNoIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete irregular day.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIrregularDay(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIrregularDay(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get IrregularDayDto by id.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIrregularDaysById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IrregularDayDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIrregularDaysById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all irregular days for selected year.
         * @param {number} year Year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIrregularDaysByYear(year: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IrregularDayDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIrregularDaysByYear(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync irregular days for the selected year from google calandar.
         * @param {number} year Year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncIrregularDays(year: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncIrregularDays(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update irregular day.
         * @param {number} id Record Id.
         * @param {IrregularDayNoIdDto} [irregularDayNoIdDto] Data to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIrregularDay(id: number, irregularDayNoIdDto?: IrregularDayNoIdDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIrregularDay(id, irregularDayNoIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * 
         * @summary Create irregular day.
         * @param {IrregularDayNoIdDto} [irregularDayNoIdDto] Input for add irregular day.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIrregularDay(irregularDayNoIdDto?: IrregularDayNoIdDto, options?: any): AxiosPromise<IdResponseDto> {
            return localVarFp.createIrregularDay(irregularDayNoIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete irregular day.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIrregularDay(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIrregularDay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get IrregularDayDto by id.
         * @param {number} id Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIrregularDaysById(id: number, options?: any): AxiosPromise<IrregularDayDto> {
            return localVarFp.getIrregularDaysById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all irregular days for selected year.
         * @param {number} year Year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIrregularDaysByYear(year: number, options?: any): AxiosPromise<Array<IrregularDayDto>> {
            return localVarFp.getIrregularDaysByYear(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync irregular days for the selected year from google calandar.
         * @param {number} year Year.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncIrregularDays(year: number, options?: any): AxiosPromise<void> {
            return localVarFp.syncIrregularDays(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update irregular day.
         * @param {number} id Record Id.
         * @param {IrregularDayNoIdDto} [irregularDayNoIdDto] Data to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIrregularDay(id: number, irregularDayNoIdDto?: IrregularDayNoIdDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateIrregularDay(id, irregularDayNoIdDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createIrregularDay operation in CalendarApi.
 * @export
 * @interface CalendarApiCreateIrregularDayRequest
 */
export interface CalendarApiCreateIrregularDayRequest {
    /**
     * Input for add irregular day.
     * @type {IrregularDayNoIdDto}
     * @memberof CalendarApiCreateIrregularDay
     */
    readonly irregularDayNoIdDto?: IrregularDayNoIdDto
}

/**
 * Request parameters for deleteIrregularDay operation in CalendarApi.
 * @export
 * @interface CalendarApiDeleteIrregularDayRequest
 */
export interface CalendarApiDeleteIrregularDayRequest {
    /**
     * Id.
     * @type {number}
     * @memberof CalendarApiDeleteIrregularDay
     */
    readonly id: number
}

/**
 * Request parameters for getIrregularDaysById operation in CalendarApi.
 * @export
 * @interface CalendarApiGetIrregularDaysByIdRequest
 */
export interface CalendarApiGetIrregularDaysByIdRequest {
    /**
     * Id.
     * @type {number}
     * @memberof CalendarApiGetIrregularDaysById
     */
    readonly id: number
}

/**
 * Request parameters for getIrregularDaysByYear operation in CalendarApi.
 * @export
 * @interface CalendarApiGetIrregularDaysByYearRequest
 */
export interface CalendarApiGetIrregularDaysByYearRequest {
    /**
     * Year.
     * @type {number}
     * @memberof CalendarApiGetIrregularDaysByYear
     */
    readonly year: number
}

/**
 * Request parameters for syncIrregularDays operation in CalendarApi.
 * @export
 * @interface CalendarApiSyncIrregularDaysRequest
 */
export interface CalendarApiSyncIrregularDaysRequest {
    /**
     * Year.
     * @type {number}
     * @memberof CalendarApiSyncIrregularDays
     */
    readonly year: number
}

/**
 * Request parameters for updateIrregularDay operation in CalendarApi.
 * @export
 * @interface CalendarApiUpdateIrregularDayRequest
 */
export interface CalendarApiUpdateIrregularDayRequest {
    /**
     * Record Id.
     * @type {number}
     * @memberof CalendarApiUpdateIrregularDay
     */
    readonly id: number

    /**
     * Data to update.
     * @type {IrregularDayNoIdDto}
     * @memberof CalendarApiUpdateIrregularDay
     */
    readonly irregularDayNoIdDto?: IrregularDayNoIdDto
}

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * 
     * @summary Create irregular day.
     * @param {CalendarApiCreateIrregularDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createIrregularDay(requestParameters: CalendarApiCreateIrregularDayRequest = {}, options?: any) {
        return CalendarApiFp(this.configuration).createIrregularDay(requestParameters.irregularDayNoIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete irregular day.
     * @param {CalendarApiDeleteIrregularDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteIrregularDay(requestParameters: CalendarApiDeleteIrregularDayRequest, options?: any) {
        return CalendarApiFp(this.configuration).deleteIrregularDay(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get IrregularDayDto by id.
     * @param {CalendarApiGetIrregularDaysByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getIrregularDaysById(requestParameters: CalendarApiGetIrregularDaysByIdRequest, options?: any) {
        return CalendarApiFp(this.configuration).getIrregularDaysById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all irregular days for selected year.
     * @param {CalendarApiGetIrregularDaysByYearRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getIrregularDaysByYear(requestParameters: CalendarApiGetIrregularDaysByYearRequest, options?: any) {
        return CalendarApiFp(this.configuration).getIrregularDaysByYear(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync irregular days for the selected year from google calandar.
     * @param {CalendarApiSyncIrregularDaysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncIrregularDays(requestParameters: CalendarApiSyncIrregularDaysRequest, options?: any) {
        return CalendarApiFp(this.configuration).syncIrregularDays(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update irregular day.
     * @param {CalendarApiUpdateIrregularDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateIrregularDay(requestParameters: CalendarApiUpdateIrregularDayRequest, options?: any) {
        return CalendarApiFp(this.configuration).updateIrregularDay(requestParameters.id, requestParameters.irregularDayNoIdDto, options).then((request) => request(this.axios, this.basePath));
    }
}
