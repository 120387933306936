import { Models, Plugin, RematchDispatch } from '@rematch/core'

interface ErrorConfig {
  name: string
}

const errorPlugin = <TModels extends Models<TModels>, TExtraModels extends Models<TModels>>(
  config: ErrorConfig = { name: 'errors' }
): Plugin<TModels, TExtraModels> => {
  return {
    onModel({ name }, rematch): void {
      const actions = Object.keys(rematch.dispatch[name])

      const wrappedActions = actions.reduce((acc, action: string) => {
        const origin = rematch.dispatch[name][action]
        const effectFn = (payload: any): any => {
          try {
            const result = origin(payload)
            const isPromise = result?.then

            if (isPromise) {
              return result
                .then((resp: any) => resp)
                .catch((error: any) => {
                  rematch.dispatch[config.name || 'errors'].add(error)
                  throw error
                })
            }

            return result
          } catch (error: any) {
            throw error
          }
        }

        return { ...acc, [action]: effectFn }
      }, {})

      rematch.dispatch[name as keyof RematchDispatch<TModels>] = wrappedActions
    }
  }
}

export default errorPlugin
