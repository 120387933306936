/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ENContactDto } from '../models';
/**
 * ENContactApi - axios parameter creator
 * @export
 */
export const ENContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get EN contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getENContact: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ENContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update EN contact.
         * @param {ENContactDto} [eNContactDto] EN contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateENContact: async (eNContactDto?: ENContactDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ENContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eNContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ENContactApi - functional programming interface
 * @export
 */
export const ENContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ENContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get EN contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getENContact(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ENContactDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getENContact(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update EN contact.
         * @param {ENContactDto} [eNContactDto] EN contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateENContact(eNContactDto?: ENContactDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateENContact(eNContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ENContactApi - factory interface
 * @export
 */
export const ENContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ENContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Get EN contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getENContact(options?: any): AxiosPromise<ENContactDto> {
            return localVarFp.getENContact(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update EN contact.
         * @param {ENContactDto} [eNContactDto] EN contact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateENContact(eNContactDto?: ENContactDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateENContact(eNContactDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateENContact operation in ENContactApi.
 * @export
 * @interface ENContactApiUpdateENContactRequest
 */
export interface ENContactApiUpdateENContactRequest {
    /**
     * EN contact.
     * @type {ENContactDto}
     * @memberof ENContactApiUpdateENContact
     */
    readonly eNContactDto?: ENContactDto
}

/**
 * ENContactApi - object-oriented interface
 * @export
 * @class ENContactApi
 * @extends {BaseAPI}
 */
export class ENContactApi extends BaseAPI {
    /**
     * 
     * @summary Get EN contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ENContactApi
     */
    public getENContact(options?: any) {
        return ENContactApiFp(this.configuration).getENContact(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update EN contact.
     * @param {ENContactApiUpdateENContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ENContactApi
     */
    public updateENContact(requestParameters: ENContactApiUpdateENContactRequest = {}, options?: any) {
        return ENContactApiFp(this.configuration).updateENContact(requestParameters.eNContactDto, options).then((request) => request(this.axios, this.basePath));
    }
}
