import { createModel } from '@rematch/core'
import { api } from '@/api'
import { PredictionApiExportScheduleRequest } from '@/api/generated'
import { downloadBlob } from '@/utils/file/downloadBlob'
import type { RootModel } from '.'

export const prediction = createModel<RootModel>()({
  state: {},
  reducers: {},
  effects: () => {
    return {
      async exportSchedule(params: PredictionApiExportScheduleRequest): Promise<void> {
        const { data, headers } = await api.prediction.exportSchedule(params, { responseType: 'blob' })
        const [, fileName] = headers['content-disposition']?.match('filename=([^;]*)')

        downloadBlob(data, fileName)
      }
    }
  }
})
