import { FC } from 'react'
import { Icon } from '@mdi/react'
import { IconProps } from '@mdi/react/dist/IconProps'
import classNames from 'classnames'

interface MaterialIconProps extends IconProps {
  path: string
  className?: string
}

const MaterialIcon: FC<MaterialIconProps> = ({ className, ...props }) => {
  return (
    <span className={classNames('ant-icon', className)}>
      <Icon size="1.25em" {...props} />
    </span>
  )
}

export default MaterialIcon
