import { FC, ReactElement } from 'react'
import { Card } from 'antd'
import classNames from 'classnames'
import Logo from '@/assets/images/panerg-alter-8.png'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import styles from './LoginLayout.module.scss'
import PublicLayout from './PublicLayout'

interface LoginLayoutProps {
  name?: string
  children: ReactElement
}

const LoginLayout: FC<LoginLayoutProps> = ({ name, children }) => {
  const isLogin = name === 'login'
  const isSmall = useBreakpoint()

  return (
    <PublicLayout contentClassName={styles.loginLayout}>
      <div
        className={classNames(styles.login, {
          [styles.centerCardAlign]: !isLogin,
          [styles.loginCardAlign]: isLogin
        })}
      >
        <Card className={styles.loginCard}>
          <img
            src={Logo}
            className={classNames(styles.logo, {
              [styles.loginLogoSmall]: isLogin && isSmall,
              [styles.loginLogo]: isLogin && !isSmall
            })}
          />
          {children}
        </Card>
      </div>
    </PublicLayout>
  )
}

export default LoginLayout
