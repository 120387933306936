import { FC, useLayoutEffect } from 'react'
import { message } from 'antd'

interface CustomErrorMessageProps {
  body: string
  timeout?: number
}

const CustomErrorMessage: FC<CustomErrorMessageProps> = ({ body, timeout }) => {
  useLayoutEffect(() => {
    message.error(body, timeout)
  }, [body])

  return <></>
}

export default CustomErrorMessage
