import { createModel } from '@rematch/core'
import { api } from '@/api'
import {
  ContactApiAddContactsToContractRequest,
  ContactApiAddContactsToCustomerRequest,
  ContactApiGetContractContactsRequest,
  ContactApiGetCustomerContactsRequest,
  ContactApiInviteContactsForContractRequest,
  ContactApiInviteContactsForCustomerRequest,
  ContactApiRemoveContactsFromContractRequest,
  ContactApiRemoveContactsFromCustomerRequest,
  ContactDto
} from '@/api/generated'
import type { RootModel } from '.'

type ContactState = {
  removeContactUserIds?: string[]
  contacts?: ContactDto[]
}

export const contact = createModel<RootModel>()({
  state: {} as ContactState,
  reducers: {
    setContacts(state, payload: ContactDto[]) {
      state.contacts = payload
    },
    setRemoveContactUserIds(state, payload: string[] | undefined) {
      state.removeContactUserIds = payload
    }
  },
  effects: dispatch => {
    const { contact } = dispatch

    return {
      async reset(): Promise<void> {
        contact.setRemoveContactUserIds(undefined)
        contact.setContacts([])
      },

      async getCustomerContacts(requestParameters: ContactApiGetCustomerContactsRequest): Promise<void> {
        const { data } = await api.contact.getCustomerContacts(requestParameters)
        contact.setContacts(data)
      },

      async addCustomerContacts(requestParameters: ContactApiAddContactsToCustomerRequest): Promise<void> {
        await api.contact.addContactsToCustomer(requestParameters)
      },

      async inviteCustomerContacts(requestParameters: ContactApiInviteContactsForCustomerRequest): Promise<void> {
        await api.contact.inviteContactsForCustomer(requestParameters)
      },

      async removeCustomerContacts(requestParameters: ContactApiRemoveContactsFromCustomerRequest): Promise<void> {
        contact.setRemoveContactUserIds([...(requestParameters.removeContactsDto?.contacts ?? [])])
        await api.contact.removeContactsFromCustomer(requestParameters)
      },

      async getContractContacts(requestParameters: ContactApiGetContractContactsRequest): Promise<void> {
        const { data } = await api.contact.getContractContacts(requestParameters)
        contact.setContacts(data)
      },

      async addContractContacts(requestParameters: ContactApiAddContactsToContractRequest): Promise<void> {
        await api.contact.addContactsToContract(requestParameters)
      },

      async inviteContractContacts(requestParameters: ContactApiInviteContactsForContractRequest): Promise<void> {
        await api.contact.inviteContactsForContract(requestParameters)
      },

      async removeContractContacts(requestParameters: ContactApiRemoveContactsFromContractRequest): Promise<void> {
        contact.setRemoveContactUserIds([...(requestParameters.removeContactsDto?.contacts ?? [])])
        await api.contact.removeContactsFromContract(requestParameters)
      }
    }
  }
})
