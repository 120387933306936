interface ENStorage {
  get: (key: string) => any
  find: (key: string, callback: (value?: any) => any) => any
  set: (key: string, value: unknown) => void
  transact: (key: string, callback: (value: any) => any) => void
  update: (key: string, value: Array<any> | Record<string, any>) => void
  remove: (key: string | string[]) => void
  userGet: (key: string) => any
  userFind: (key: string, callback: (value?: any) => any) => any
  userSet: (key: string, value: unknown) => void
  userUpdate: (key: string, value: Array<any> | Record<string, any>) => void
}

const storagePrefix = 'en'

const get = (storage: Storage, key: string): any => {
  const item = storage.getItem(`${storagePrefix}-${key}`)

  try {
    return JSON.parse(item || '')
  } catch {
    return item
  }
}

const find = (storage: Storage, key: string, callback: (value?: any) => any): any => {
  const item = get(storage, key)
  return callback(item)
}

const set = (storage: Storage, key: string, value: unknown): void => {
  storage.setItem(`${storagePrefix}-${key}`, JSON.stringify(value))
}

const transact = (storage: Storage, key: string, callback: (value: any) => any): void => {
  const item = get(storage, key)
  set(storage, key, callback(item))
}

const update = (storage: Storage, key: string, value: Array<any> | Record<string, any>): void => {
  transact(storage, key, (stored: any) => {
    if (Array.isArray(value)) {
      return stored?.concat(value)
    }

    return { ...stored, ...value }
  })
}

const remove = (storage: Storage, key: string | string[]): void => {
  if (Array.isArray(key)) {
    key.forEach((currentKey: string) => {
      storage.removeItem(`${storagePrefix}-${currentKey}`)
    })
  } else {
    storage.removeItem(`${storagePrefix}-${key}`)
  }
}

const getUserId = (): any => {
  const user = get(localStorage, 'user')
  return user?.name
}

const userGet = (storage: Storage, key: string): any => {
  const userId = getUserId()
  return get(storage, `${userId}-${key}`)
}

const userFind = (storage: Storage, key: string, callback: (value?: any) => any): any => {
  const userId = getUserId()
  return find(storage, `${userId}-${key}`, callback)
}

const userSet = (storage: Storage, key: string, value: unknown): void => {
  const userId = getUserId()
  set(storage, `${userId}-${key}`, value)
}

const userUpdate = (storage: Storage, key: string, value: Array<any> | Record<string, any>): void => {
  const userId = getUserId()
  update(storage, `${userId}-${key}`, value)
}

export const ENLocalStorage: ENStorage = {
  get: (...props) => get(localStorage, ...props),
  find: (...props) => find(localStorage, ...props),
  set: (...props) => set(localStorage, ...props),
  transact: (...props) => transact(localStorage, ...props),
  update: (...props) => update(localStorage, ...props),
  remove: (...props) => remove(localStorage, ...props),
  userGet: (...props) => userGet(localStorage, ...props),
  userFind: (...props) => userFind(localStorage, ...props),
  userSet: (...props) => userSet(localStorage, ...props),
  userUpdate: (...props) => userUpdate(localStorage, ...props)
}

export const ENSessionStorage: ENStorage = {
  get: (...props) => get(sessionStorage, ...props),
  find: (...props) => find(sessionStorage, ...props),
  set: (...props) => set(sessionStorage, ...props),
  transact: (...props) => transact(sessionStorage, ...props),
  update: (...props) => update(sessionStorage, ...props),
  remove: (...props) => remove(sessionStorage, ...props),
  userGet: (...props) => userGet(sessionStorage, ...props),
  userFind: (...props) => userFind(sessionStorage, ...props),
  userSet: (...props) => userSet(sessionStorage, ...props),
  userUpdate: (...props) => userUpdate(sessionStorage, ...props)
}
