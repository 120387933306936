import { Children, cloneElement, ReactElement, useContext } from 'react'
import { Form, FormItemProps } from 'antd'
import { BaseRule, requiredField } from '@/utils/formRules'
import { getSchemaRules } from '@/utils/schema'
import { CustomFormContext } from './CustomForm'

interface CustomFormItemProps extends FormItemProps {
  name?: string | string[]
  rules?: any
}

const CustomFormItem = (props: CustomFormItemProps): ReactElement => {
  // form item props
  const { name, rules, children, label, ...formItemProps } = props

  // props from custom form context
  const {
    loading,
    formUtils: { schema, required: requiredFields }
  } = useContext(CustomFormContext)

  // get constraints from schema
  const constraints = (name && getSchemaRules(schema, name, label as string)) || []

  // handle rules from schema and form item rule prop together
  const mergedRules = [...constraints, ...(rules ?? [])]

  // check if rules already contain required
  const isMergedAlreadyContainsRequired = mergedRules.some(({ required }: BaseRule) => required)

  // add additional required if it's a required field and merged constraints doesn't
  const addRequiredRule =
    !isMergedAlreadyContainsRequired &&
    (Array.isArray(name) ? requiredFields.includes(name.join('.')) : requiredFields.includes(name))

  // create the final form field ruleset
  const fieldRules = [...mergedRules, ...(addRequiredRule ? [requiredField(label as string)] : [])]

  // clone form item children and override disabled property
  const child = !Array.isArray(children)
    ? cloneElement(children as ReactElement, {
        disabled: loading || (children as ReactElement)?.props?.disabled
      })
    : Children.map(children, child =>
        cloneElement(child as ReactElement, {
          disabled: loading || (child as ReactElement)?.props?.disabled
        })
      )

  return (
    <Form.Item name={name} rules={fieldRules} label={label} {...formItemProps}>
      {child}
    </Form.Item>
  )
}

export default CustomFormItem
