import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Col, FormInstance, Input, message, Row, Typography } from 'antd'
import { mdiAlertOctagon } from '@mdi/js'
import { ModifyPasswordDto } from '@/api/generated'
import { createCustomForm } from '@/components/customForm'
import CustomModal from '@/components/customModal/CustomModal'
import MaterialIcon from '@/components/icon/MaterialIcon'
import { ModalUtils } from '@/hooks/useModalUtils'
import { useDispatch, useSelector } from '@/store'

interface PasswordChangeModalProps {
  modalUtils?: ModalUtils
}

const PasswordChangeModal: FC<PasswordChangeModalProps> = ({ modalUtils }) => {
  const { t } = useTranslation()
  const { formUtils, CustomForm } = createCustomForm('ModifyPasswordDto')
  const dispatch = useDispatch()
  const [error, setError] = useState<string | null>(null)
  const { loading, success } = useSelector(state => state.loading.effects.profile.updateProfilePassword)

  const handleFinish = async (data: ModifyPasswordDto): Promise<void> => {
    dispatch.profile
      .updateProfilePassword(data)
      .then(() => {
        modalUtils?.close()
        message.success(t('password-change.success'))
      })
      .catch(({ status }) => {
        const msg = status === 500 ? t('error.common.unknown-error') : t('error.password-change.check-passwords')
        setError(msg)
      })
  }

  const handleCancel = (): void => {
    modalUtils?.close()
  }

  const handleAfterClose = (): void => {
    formUtils.form.resetFields()
    setError(null)

    // reset effect loading state
    dispatch.loading.hide({
      name: 'profile',
      action: 'updateProfilePassword',
      detailedPayload: { success: false, loading: false, error: false }
    })
  }

  return (
    <CustomModal
      visible={modalUtils?.state.visible}
      afterClose={handleAfterClose}
      onCancel={handleCancel}
      loading={loading}
      formUtils={formUtils}
      cancelText={(success && t('common.close')) || t('common.cancel')}
      size="xs"
      okButtonProps={{
        hidden: success
      }}
    >
      <Row justify="center">
        <Col md={18}>
          <Typography.Title level={4}>{t('password-change.title')}</Typography.Title>

          <CustomForm formUtils={formUtils} onFinish={handleFinish} loading={loading}>
            <CustomForm.Item label={t('password-change.current-password')} name="oldPassword">
              <Input.Password />
            </CustomForm.Item>

            <Typography.Paragraph>{t('password-change.hint')}</Typography.Paragraph>

            <CustomForm.Item label={t('password-change.password')} name="password">
              <Input.Password />
            </CustomForm.Item>

            <CustomForm.Item
              label={t('password-change.password-again')}
              name="passwordAgain"
              dependencies={['password']}
              rules={[
                ({ getFieldValue }: FormInstance) => ({
                  validator(_: any, value?: string) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(t('error.password-change.password-inconsistent'))
                  }
                })
              ]}
            >
              <Input.Password />
            </CustomForm.Item>
          </CustomForm>

          {error && (
            <Alert
              message={t('common.error')}
              description={error}
              type="error"
              icon={<MaterialIcon path={mdiAlertOctagon} />}
              showIcon
            />
          )}
        </Col>
      </Row>
    </CustomModal>
  )
}

export default PasswordChangeModal
