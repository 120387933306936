import { createModel } from '@rematch/core'
import { api } from '@/api'
import { EnvironmentSettingsDto } from '@/api/generated'
import type { RootModel } from '.'

type EnvironmentSettingsState = {
  environmentSettings?: EnvironmentSettingsDto
}

export const environmentSettings = createModel<RootModel>()({
  state: {} as EnvironmentSettingsState,
  reducers: {
    setEnvironmentSettings(state, payload: EnvironmentSettingsDto) {
      state.environmentSettings = payload
    }
  },
  effects: dispatch => {
    const { environmentSettings } = dispatch

    return {
      async getEnvironmentSettings(): Promise<void> {
        const { data } = await api.environmentSettings.getEnvironmentSettings()

        environmentSettings.setEnvironmentSettings(data)
      }
    }
  }
})
