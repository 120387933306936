import { FC, useMemo } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Badge, Menu, MenuProps } from 'antd'
import classNames from 'classnames'
import { Role } from '@/models/role'
import { getAllowedRouteMap } from '@/routes'
import { useSelector } from '@/store'
import styles from './TopMenu.module.scss'

const TopMenu: FC<MenuProps> = ({ mode = 'horizontal', ...props }) => {
  const { pathname } = useLocation()
  const { hasUnpaired } = useSelector(state => state.device)
  const { user } = useSelector(state => state.auth)
  const pathRoot = useMemo(() => `/${pathname.split('/')[1]}`, [pathname])
  const menuItems = getAllowedRouteMap()
    .filter(({ menuOptions }) => menuOptions?.showMenu)
    .map(item => {
      return (
        <Menu.Item
          key={item.name}
          icon={item.menuOptions?.icon}
          className={classNames({
            'ant-menu-item-selected': pathRoot === item.path,
            [styles.menuItemRight]: item.menuOptions?.right
          })}
        >
          {user?.role === Role.Admin && item.path == '/devices' ? (
            <Link to={item.path as string}>
              <Badge dot={hasUnpaired} className={styles.badge}>
                {item.menuOptions?.title}
              </Badge>
            </Link>
          ) : (
            <Link to={item.path as string}>{item.menuOptions?.title}</Link>
          )}
        </Menu.Item>
      )
    })

  return (
    <Menu mode={mode} className={styles.topMenu} {...props}>
      {menuItems}
    </Menu>
  )
}

export default TopMenu
