import { createModel } from '@rematch/core'
import { api } from '@/api'
import {
  CalendarApiCreateIrregularDayRequest,
  CalendarApiUpdateIrregularDayRequest,
  IrregularDayDto
} from '@/api/generated'
import type { RootModel } from '.'

type CalendarState = {
  irregularDays?: IrregularDayDto[]
  irregularDay?: IrregularDayDto
}

export const calendar = createModel<RootModel>()({
  state: {} as CalendarState,
  reducers: {
    resetIrregularDay(state) {
      state.irregularDay = undefined
    },
    setIrregularDays(state, irregularDays: IrregularDayDto[]) {
      state.irregularDays = irregularDays
    },
    setIrregularDay(state, irregularDay: IrregularDayDto) {
      state.irregularDay = irregularDay
    }
  },
  effects: dispatch => {
    const { calendar } = dispatch

    return {
      async getIrregularDays(year: number): Promise<void> {
        const { data } = await api.calendar.getIrregularDaysByYear({ year })
        calendar.setIrregularDays(data)
      },

      async getIrregularDay(id: number): Promise<void> {
        const { data } = await api.calendar.getIrregularDaysById({ id })
        calendar.setIrregularDay(data)
      },

      async createIrregularDay({ irregularDayNoIdDto }: CalendarApiCreateIrregularDayRequest): Promise<void> {
        await api.calendar.createIrregularDay({ irregularDayNoIdDto })
      },

      async updateIrregularDay({ id, irregularDayNoIdDto }: CalendarApiUpdateIrregularDayRequest): Promise<void> {
        await api.calendar.updateIrregularDay({ id, irregularDayNoIdDto })
      },

      async deleteIrregularDay(id: number): Promise<void> {
        await api.calendar.deleteIrregularDay({ id })
      }
    }
  }
})
