/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChartDto } from '../models';
/**
 * EnergyCostApi - axios parameter creator
 * @export
 */
export const EnergyCostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get energy costs.
         * @param {string} fromDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyCostInfo: async (fromDate: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getEnergyCostInfo', 'fromDate', fromDate)
            const localVarPath = `/api/external/EnergyCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get energy costs.
         * @param {string} fromDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyCosts: async (fromDate: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getEnergyCosts', 'fromDate', fromDate)
            const localVarPath = `/api/EnergyCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergyCostApi - functional programming interface
 * @export
 */
export const EnergyCostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnergyCostApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get energy costs.
         * @param {string} fromDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyCostInfo(fromDate: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergyCostInfo(fromDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get energy costs.
         * @param {string} fromDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyCosts(fromDate: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergyCosts(fromDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnergyCostApi - factory interface
 * @export
 */
export const EnergyCostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnergyCostApiFp(configuration)
    return {
        /**
         * 
         * @summary Get energy costs.
         * @param {string} fromDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyCostInfo(fromDate: string, endDate?: string, options?: any): AxiosPromise<ChartDto> {
            return localVarFp.getEnergyCostInfo(fromDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get energy costs.
         * @param {string} fromDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyCosts(fromDate: string, endDate?: string, options?: any): AxiosPromise<ChartDto> {
            return localVarFp.getEnergyCosts(fromDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEnergyCostInfo operation in EnergyCostApi.
 * @export
 * @interface EnergyCostApiGetEnergyCostInfoRequest
 */
export interface EnergyCostApiGetEnergyCostInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof EnergyCostApiGetEnergyCostInfo
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof EnergyCostApiGetEnergyCostInfo
     */
    readonly endDate?: string
}

/**
 * Request parameters for getEnergyCosts operation in EnergyCostApi.
 * @export
 * @interface EnergyCostApiGetEnergyCostsRequest
 */
export interface EnergyCostApiGetEnergyCostsRequest {
    /**
     * 
     * @type {string}
     * @memberof EnergyCostApiGetEnergyCosts
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof EnergyCostApiGetEnergyCosts
     */
    readonly endDate?: string
}

/**
 * EnergyCostApi - object-oriented interface
 * @export
 * @class EnergyCostApi
 * @extends {BaseAPI}
 */
export class EnergyCostApi extends BaseAPI {
    /**
     * 
     * @summary Get energy costs.
     * @param {EnergyCostApiGetEnergyCostInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyCostApi
     */
    public getEnergyCostInfo(requestParameters: EnergyCostApiGetEnergyCostInfoRequest, options?: any) {
        return EnergyCostApiFp(this.configuration).getEnergyCostInfo(requestParameters.fromDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get energy costs.
     * @param {EnergyCostApiGetEnergyCostsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyCostApi
     */
    public getEnergyCosts(requestParameters: EnergyCostApiGetEnergyCostsRequest, options?: any) {
        return EnergyCostApiFp(this.configuration).getEnergyCosts(requestParameters.fromDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }
}
