/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminRegistrationDto } from '../models';
// @ts-ignore
import { ChangePasswordDto } from '../models';
// @ts-ignore
import { ExtendedUserDtoPaginatedList } from '../models';
// @ts-ignore
import { ModifyPasswordDto } from '../models';
// @ts-ignore
import { PasswordResetDto } from '../models';
// @ts-ignore
import { ProfileDto } from '../models';
// @ts-ignore
import { RegTokenValidationResponseDto } from '../models';
// @ts-ignore
import { TokenPurpose } from '../models';
// @ts-ignore
import { UpdateUserDto } from '../models';
// @ts-ignore
import { UserRegistrationDto } from '../models';
// @ts-ignore
import { UserRegistrationStates } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm user email update with confirmation token.
         * @param {string} [token] Change email token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailUpdate: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Email/Confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user email address by admin users.
         * @param {string} userId User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEmailAdmin: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserEmailAdmin', 'userId', userId)
            const localVarPath = `/api/User/{userId}/Email`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get flitered paginated user list.
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {Array<UserRegistrationStates>} [registrationState] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (role?: string, name?: string, id?: string, email?: string, phoneNumber?: string, registrationState?: Array<UserRegistrationStates>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['PhoneNumber'] = phoneNumber;
            }

            if (registrationState) {
                localVarQueryParameter['RegistrationState'] = registrationState;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderByAsc !== undefined) {
                localVarQueryParameter['OrderByAsc'] = orderByAsc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user data and customer information in case of customers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user data and customer information in case of customers.
         * @param {string} id User Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/api/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Grant access to maually locked out  user.
         * @param {string} id User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAccessUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('grantAccessUser', 'id', id)
            const localVarPath = `/api/User/{id}/GrantAccess`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite new admin user.
         * @param {AdminRegistrationDto} [adminRegistrationDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteAdmin: async (adminRegistrationDto?: AdminRegistrationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Admin/Invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Action for password reset.
         * @param {PasswordResetDto} [passwordResetDto] Input for password reset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset: async (passwordResetDto?: PasswordResetDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Password/Reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend user invitation email.
         * @param {string} [userId] User Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitation: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Invitation/Resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set new password.
         * @param {string} [token] Token for change password.
         * @param {ChangePasswordDto} [changePasswordDto] Input datas for change password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: async (token?: string, changePasswordDto?: ChangePasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Password/Set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get email address and user role information from tokens.  Error in case of invalid tokens.
         * @param {string} [token] Token value.
         * @param {TokenPurpose} [tokenPurpose] Token type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenInfo: async (token?: string, tokenPurpose?: TokenPurpose, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Token/Info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (tokenPurpose !== undefined) {
                localVarQueryParameter['tokenPurpose'] = tokenPurpose;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing password.
         * @param {ModifyPasswordDto} [modifyPasswordDto] Input datas for modify password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (modifyPasswordDto?: ModifyPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Password/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user.
         * @param {string} id User id.
         * @param {UpdateUserDto} [updateUserDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, updateUserDto?: UpdateUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            const localVarPath = `/api/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user.
         * @param {string} id User id.
         * @param {UpdateUserDto} [updateUserDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAdmin: async (id: string, updateUserDto?: UpdateUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserAdmin', 'id', id)
            const localVarPath = `/api/User/Admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user own email address.
         * @param {string} [newEmail] New email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmail: async (newEmail?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (newEmail !== undefined) {
                localVarQueryParameter['newEmail'] = newEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user email address by admin users.
         * @param {string} userId User id.
         * @param {string} [newEmail] New email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmailAdmin: async (userId: string, newEmail?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserEmailAdmin', 'userId', userId)
            const localVarPath = `/api/User/{userId}/Email`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (newEmail !== undefined) {
                localVarQueryParameter['newEmail'] = newEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manualy lockout user.
         * @param {string} id User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLockout: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userLockout', 'id', id)
            const localVarPath = `/api/User/{id}/Lockout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finalize user registration with the confirmation token.
         * @param {string} [token] Token for user registrartion.
         * @param {boolean} [isTermsAccepted] Is terms os service accepted.
         * @param {UserRegistrationDto} [userRegistrationDto] Input datas for user registration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegistration: async (token?: string, isTermsAccepted?: boolean, userRegistrationDto?: UserRegistrationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (isTermsAccepted !== undefined) {
                localVarQueryParameter['isTermsAccepted'] = isTermsAccepted;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm user email update with confirmation token.
         * @param {string} [token] Change email token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmailUpdate(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmailUpdate(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user email address by admin users.
         * @param {string} userId User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserEmailAdmin(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserEmailAdmin(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get flitered paginated user list.
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {Array<UserRegistrationStates>} [registrationState] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(role?: string, name?: string, id?: string, email?: string, phoneNumber?: string, registrationState?: Array<UserRegistrationStates>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedUserDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(role, name, id, email, phoneNumber, registrationState, skip, take, orderBy, orderByAsc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user data and customer information in case of customers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user data and customer information in case of customers.
         * @param {string} id User Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Grant access to maually locked out  user.
         * @param {string} id User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantAccessUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantAccessUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite new admin user.
         * @param {AdminRegistrationDto} [adminRegistrationDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteAdmin(adminRegistrationDto?: AdminRegistrationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteAdmin(adminRegistrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Action for password reset.
         * @param {PasswordResetDto} [passwordResetDto] Input for password reset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordReset(passwordResetDto?: PasswordResetDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordReset(passwordResetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend user invitation email.
         * @param {string} [userId] User Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendInvitation(userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendInvitation(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set new password.
         * @param {string} [token] Token for change password.
         * @param {ChangePasswordDto} [changePasswordDto] Input datas for change password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPassword(token?: string, changePasswordDto?: ChangePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPassword(token, changePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get email address and user role information from tokens.  Error in case of invalid tokens.
         * @param {string} [token] Token value.
         * @param {TokenPurpose} [tokenPurpose] Token type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenInfo(token?: string, tokenPurpose?: TokenPurpose, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegTokenValidationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenInfo(token, tokenPurpose, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing password.
         * @param {ModifyPasswordDto} [modifyPasswordDto] Input datas for modify password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(modifyPasswordDto?: ModifyPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(modifyPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user.
         * @param {string} id User id.
         * @param {UpdateUserDto} [updateUserDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, updateUserDto?: UpdateUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user.
         * @param {string} id User id.
         * @param {UpdateUserDto} [updateUserDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserAdmin(id: string, updateUserDto?: UpdateUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserAdmin(id, updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user own email address.
         * @param {string} [newEmail] New email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserEmail(newEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserEmail(newEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user email address by admin users.
         * @param {string} userId User id.
         * @param {string} [newEmail] New email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserEmailAdmin(userId: string, newEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserEmailAdmin(userId, newEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manualy lockout user.
         * @param {string} id User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLockout(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLockout(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Finalize user registration with the confirmation token.
         * @param {string} [token] Token for user registrartion.
         * @param {boolean} [isTermsAccepted] Is terms os service accepted.
         * @param {UserRegistrationDto} [userRegistrationDto] Input datas for user registration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegistration(token?: string, isTermsAccepted?: boolean, userRegistrationDto?: UserRegistrationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegistration(token, isTermsAccepted, userRegistrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm user email update with confirmation token.
         * @param {string} [token] Change email token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailUpdate(token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.confirmEmailUpdate(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user email address by admin users.
         * @param {string} userId User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEmailAdmin(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserEmailAdmin(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get flitered paginated user list.
         * @param {string} [role] 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {Array<UserRegistrationStates>} [registrationState] 
         * @param {number} [skip] Skip items for paging.
         * @param {number} [take] Take items for paging.
         * @param {string} [orderBy] Order by column name.
         * @param {boolean} [orderByAsc] Is order by ascending or descending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(role?: string, name?: string, id?: string, email?: string, phoneNumber?: string, registrationState?: Array<UserRegistrationStates>, skip?: number, take?: number, orderBy?: string, orderByAsc?: boolean, options?: any): AxiosPromise<ExtendedUserDtoPaginatedList> {
            return localVarFp.getAll(role, name, id, email, phoneNumber, registrationState, skip, take, orderBy, orderByAsc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user data and customer information in case of customers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<ProfileDto> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user data and customer information in case of customers.
         * @param {string} id User Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<ProfileDto> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Grant access to maually locked out  user.
         * @param {string} id User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAccessUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.grantAccessUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite new admin user.
         * @param {AdminRegistrationDto} [adminRegistrationDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteAdmin(adminRegistrationDto?: AdminRegistrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.inviteAdmin(adminRegistrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Action for password reset.
         * @param {PasswordResetDto} [passwordResetDto] Input for password reset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordReset(passwordResetDto?: PasswordResetDto, options?: any): AxiosPromise<void> {
            return localVarFp.passwordReset(passwordResetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend user invitation email.
         * @param {string} [userId] User Id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitation(userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendInvitation(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set new password.
         * @param {string} [token] Token for change password.
         * @param {ChangePasswordDto} [changePasswordDto] Input datas for change password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword(token?: string, changePasswordDto?: ChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.setPassword(token, changePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get email address and user role information from tokens.  Error in case of invalid tokens.
         * @param {string} [token] Token value.
         * @param {TokenPurpose} [tokenPurpose] Token type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenInfo(token?: string, tokenPurpose?: TokenPurpose, options?: any): AxiosPromise<RegTokenValidationResponseDto> {
            return localVarFp.tokenInfo(token, tokenPurpose, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing password.
         * @param {ModifyPasswordDto} [modifyPasswordDto] Input datas for modify password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(modifyPasswordDto?: ModifyPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.updatePassword(modifyPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user.
         * @param {string} id User id.
         * @param {UpdateUserDto} [updateUserDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, updateUserDto?: UpdateUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(id, updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user.
         * @param {string} id User id.
         * @param {UpdateUserDto} [updateUserDto] User data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAdmin(id: string, updateUserDto?: UpdateUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserAdmin(id, updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user own email address.
         * @param {string} [newEmail] New email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmail(newEmail?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserEmail(newEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user email address by admin users.
         * @param {string} userId User id.
         * @param {string} [newEmail] New email address.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmailAdmin(userId: string, newEmail?: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserEmailAdmin(userId, newEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manualy lockout user.
         * @param {string} id User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLockout(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userLockout(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finalize user registration with the confirmation token.
         * @param {string} [token] Token for user registrartion.
         * @param {boolean} [isTermsAccepted] Is terms os service accepted.
         * @param {UserRegistrationDto} [userRegistrationDto] Input datas for user registration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegistration(token?: string, isTermsAccepted?: boolean, userRegistrationDto?: UserRegistrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.userRegistration(token, isTermsAccepted, userRegistrationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for confirmEmailUpdate operation in UserApi.
 * @export
 * @interface UserApiConfirmEmailUpdateRequest
 */
export interface UserApiConfirmEmailUpdateRequest {
    /**
     * Change email token.
     * @type {string}
     * @memberof UserApiConfirmEmailUpdate
     */
    readonly token?: string
}

/**
 * Request parameters for deleteUserEmailAdmin operation in UserApi.
 * @export
 * @interface UserApiDeleteUserEmailAdminRequest
 */
export interface UserApiDeleteUserEmailAdminRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserApiDeleteUserEmailAdmin
     */
    readonly userId: string
}

/**
 * Request parameters for getAll operation in UserApi.
 * @export
 * @interface UserApiGetAllRequest
 */
export interface UserApiGetAllRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiGetAll
     */
    readonly role?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiGetAll
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiGetAll
     */
    readonly id?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiGetAll
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiGetAll
     */
    readonly phoneNumber?: string

    /**
     * 
     * @type {Array<UserRegistrationStates>}
     * @memberof UserApiGetAll
     */
    readonly registrationState?: Array<UserRegistrationStates>

    /**
     * Skip items for paging.
     * @type {number}
     * @memberof UserApiGetAll
     */
    readonly skip?: number

    /**
     * Take items for paging.
     * @type {number}
     * @memberof UserApiGetAll
     */
    readonly take?: number

    /**
     * Order by column name.
     * @type {string}
     * @memberof UserApiGetAll
     */
    readonly orderBy?: string

    /**
     * Is order by ascending or descending.
     * @type {boolean}
     * @memberof UserApiGetAll
     */
    readonly orderByAsc?: boolean
}

/**
 * Request parameters for getUserById operation in UserApi.
 * @export
 * @interface UserApiGetUserByIdRequest
 */
export interface UserApiGetUserByIdRequest {
    /**
     * User Id.
     * @type {string}
     * @memberof UserApiGetUserById
     */
    readonly id: string
}

/**
 * Request parameters for grantAccessUser operation in UserApi.
 * @export
 * @interface UserApiGrantAccessUserRequest
 */
export interface UserApiGrantAccessUserRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserApiGrantAccessUser
     */
    readonly id: string
}

/**
 * Request parameters for inviteAdmin operation in UserApi.
 * @export
 * @interface UserApiInviteAdminRequest
 */
export interface UserApiInviteAdminRequest {
    /**
     * User data.
     * @type {AdminRegistrationDto}
     * @memberof UserApiInviteAdmin
     */
    readonly adminRegistrationDto?: AdminRegistrationDto
}

/**
 * Request parameters for passwordReset operation in UserApi.
 * @export
 * @interface UserApiPasswordResetRequest
 */
export interface UserApiPasswordResetRequest {
    /**
     * Input for password reset.
     * @type {PasswordResetDto}
     * @memberof UserApiPasswordReset
     */
    readonly passwordResetDto?: PasswordResetDto
}

/**
 * Request parameters for resendInvitation operation in UserApi.
 * @export
 * @interface UserApiResendInvitationRequest
 */
export interface UserApiResendInvitationRequest {
    /**
     * User Id.
     * @type {string}
     * @memberof UserApiResendInvitation
     */
    readonly userId?: string
}

/**
 * Request parameters for setPassword operation in UserApi.
 * @export
 * @interface UserApiSetPasswordRequest
 */
export interface UserApiSetPasswordRequest {
    /**
     * Token for change password.
     * @type {string}
     * @memberof UserApiSetPassword
     */
    readonly token?: string

    /**
     * Input datas for change password.
     * @type {ChangePasswordDto}
     * @memberof UserApiSetPassword
     */
    readonly changePasswordDto?: ChangePasswordDto
}

/**
 * Request parameters for tokenInfo operation in UserApi.
 * @export
 * @interface UserApiTokenInfoRequest
 */
export interface UserApiTokenInfoRequest {
    /**
     * Token value.
     * @type {string}
     * @memberof UserApiTokenInfo
     */
    readonly token?: string

    /**
     * Token type.
     * @type {TokenPurpose}
     * @memberof UserApiTokenInfo
     */
    readonly tokenPurpose?: TokenPurpose
}

/**
 * Request parameters for updatePassword operation in UserApi.
 * @export
 * @interface UserApiUpdatePasswordRequest
 */
export interface UserApiUpdatePasswordRequest {
    /**
     * Input datas for modify password.
     * @type {ModifyPasswordDto}
     * @memberof UserApiUpdatePassword
     */
    readonly modifyPasswordDto?: ModifyPasswordDto
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserApiUpdateUser
     */
    readonly id: string

    /**
     * User data.
     * @type {UpdateUserDto}
     * @memberof UserApiUpdateUser
     */
    readonly updateUserDto?: UpdateUserDto
}

/**
 * Request parameters for updateUserAdmin operation in UserApi.
 * @export
 * @interface UserApiUpdateUserAdminRequest
 */
export interface UserApiUpdateUserAdminRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserApiUpdateUserAdmin
     */
    readonly id: string

    /**
     * User data.
     * @type {UpdateUserDto}
     * @memberof UserApiUpdateUserAdmin
     */
    readonly updateUserDto?: UpdateUserDto
}

/**
 * Request parameters for updateUserEmail operation in UserApi.
 * @export
 * @interface UserApiUpdateUserEmailRequest
 */
export interface UserApiUpdateUserEmailRequest {
    /**
     * New email address.
     * @type {string}
     * @memberof UserApiUpdateUserEmail
     */
    readonly newEmail?: string
}

/**
 * Request parameters for updateUserEmailAdmin operation in UserApi.
 * @export
 * @interface UserApiUpdateUserEmailAdminRequest
 */
export interface UserApiUpdateUserEmailAdminRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserApiUpdateUserEmailAdmin
     */
    readonly userId: string

    /**
     * New email address.
     * @type {string}
     * @memberof UserApiUpdateUserEmailAdmin
     */
    readonly newEmail?: string
}

/**
 * Request parameters for userLockout operation in UserApi.
 * @export
 * @interface UserApiUserLockoutRequest
 */
export interface UserApiUserLockoutRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserApiUserLockout
     */
    readonly id: string
}

/**
 * Request parameters for userRegistration operation in UserApi.
 * @export
 * @interface UserApiUserRegistrationRequest
 */
export interface UserApiUserRegistrationRequest {
    /**
     * Token for user registrartion.
     * @type {string}
     * @memberof UserApiUserRegistration
     */
    readonly token?: string

    /**
     * Is terms os service accepted.
     * @type {boolean}
     * @memberof UserApiUserRegistration
     */
    readonly isTermsAccepted?: boolean

    /**
     * Input datas for user registration.
     * @type {UserRegistrationDto}
     * @memberof UserApiUserRegistration
     */
    readonly userRegistrationDto?: UserRegistrationDto
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Confirm user email update with confirmation token.
     * @param {UserApiConfirmEmailUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public confirmEmailUpdate(requestParameters: UserApiConfirmEmailUpdateRequest = {}, options?: any) {
        return UserApiFp(this.configuration).confirmEmailUpdate(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user email address by admin users.
     * @param {UserApiDeleteUserEmailAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserEmailAdmin(requestParameters: UserApiDeleteUserEmailAdminRequest, options?: any) {
        return UserApiFp(this.configuration).deleteUserEmailAdmin(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get flitered paginated user list.
     * @param {UserApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAll(requestParameters: UserApiGetAllRequest = {}, options?: any) {
        return UserApiFp(this.configuration).getAll(requestParameters.role, requestParameters.name, requestParameters.id, requestParameters.email, requestParameters.phoneNumber, requestParameters.registrationState, requestParameters.skip, requestParameters.take, requestParameters.orderBy, requestParameters.orderByAsc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user data and customer information in case of customers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(options?: any) {
        return UserApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user data and customer information in case of customers.
     * @param {UserApiGetUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(requestParameters: UserApiGetUserByIdRequest, options?: any) {
        return UserApiFp(this.configuration).getUserById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Grant access to maually locked out  user.
     * @param {UserApiGrantAccessUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public grantAccessUser(requestParameters: UserApiGrantAccessUserRequest, options?: any) {
        return UserApiFp(this.configuration).grantAccessUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite new admin user.
     * @param {UserApiInviteAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public inviteAdmin(requestParameters: UserApiInviteAdminRequest = {}, options?: any) {
        return UserApiFp(this.configuration).inviteAdmin(requestParameters.adminRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Action for password reset.
     * @param {UserApiPasswordResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public passwordReset(requestParameters: UserApiPasswordResetRequest = {}, options?: any) {
        return UserApiFp(this.configuration).passwordReset(requestParameters.passwordResetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend user invitation email.
     * @param {UserApiResendInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resendInvitation(requestParameters: UserApiResendInvitationRequest = {}, options?: any) {
        return UserApiFp(this.configuration).resendInvitation(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set new password.
     * @param {UserApiSetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public setPassword(requestParameters: UserApiSetPasswordRequest = {}, options?: any) {
        return UserApiFp(this.configuration).setPassword(requestParameters.token, requestParameters.changePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get email address and user role information from tokens.  Error in case of invalid tokens.
     * @param {UserApiTokenInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public tokenInfo(requestParameters: UserApiTokenInfoRequest = {}, options?: any) {
        return UserApiFp(this.configuration).tokenInfo(requestParameters.token, requestParameters.tokenPurpose, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing password.
     * @param {UserApiUpdatePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePassword(requestParameters: UserApiUpdatePasswordRequest = {}, options?: any) {
        return UserApiFp(this.configuration).updatePassword(requestParameters.modifyPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user.
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest, options?: any) {
        return UserApiFp(this.configuration).updateUser(requestParameters.id, requestParameters.updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user.
     * @param {UserApiUpdateUserAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserAdmin(requestParameters: UserApiUpdateUserAdminRequest, options?: any) {
        return UserApiFp(this.configuration).updateUserAdmin(requestParameters.id, requestParameters.updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user own email address.
     * @param {UserApiUpdateUserEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserEmail(requestParameters: UserApiUpdateUserEmailRequest = {}, options?: any) {
        return UserApiFp(this.configuration).updateUserEmail(requestParameters.newEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user email address by admin users.
     * @param {UserApiUpdateUserEmailAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserEmailAdmin(requestParameters: UserApiUpdateUserEmailAdminRequest, options?: any) {
        return UserApiFp(this.configuration).updateUserEmailAdmin(requestParameters.userId, requestParameters.newEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manualy lockout user.
     * @param {UserApiUserLockoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLockout(requestParameters: UserApiUserLockoutRequest, options?: any) {
        return UserApiFp(this.configuration).userLockout(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finalize user registration with the confirmation token.
     * @param {UserApiUserRegistrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRegistration(requestParameters: UserApiUserRegistrationRequest = {}, options?: any) {
        return UserApiFp(this.configuration).userRegistration(requestParameters.token, requestParameters.isTermsAccepted, requestParameters.userRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }
}
