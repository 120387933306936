import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import enGB from 'antd/lib/locale/en_GB'
import huHU from 'antd/lib/locale/hu_HU'
import { Locale } from 'antd/lib/locale-provider'
import { ConnectedRouter } from 'connected-react-router'
import { RouterView } from './routes'
import { history, store, useDispatch } from './store'

function AppInner(): ReactElement {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  useEffect(() => {
    dispatch.environmentSettings.getEnvironmentSettings()
  }, [])

  const antLocale: () => Locale | undefined = () => {
    switch (i18n.language) {
      case 'en':
        return enGB
      case 'hu':
        return huHU
    }
  }

  return (
    <ConnectedRouter history={history}>
      <ConfigProvider locale={antLocale()}>
        <RouterView />
      </ConfigProvider>
    </ConnectedRouter>
  )
}

function App(): ReactElement {
  return (
    <Provider store={store}>
      <AppInner />
    </Provider>
  )
}

export default App
