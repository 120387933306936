import { createModel, RematchRootState } from '@rematch/core'
import { api } from '@/api'
import { AuditLogApiGetAllAuditLogRequest, AuditLogDto } from '@/api/generated'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import type { RootModel } from '.'

type AuditlogState = {
  logs?: AuditLogDto[]
  auditlogListParams?: ListRequestParams
}

export const auditlog = createModel<RootModel>()({
  state: {} as AuditlogState,
  reducers: {
    reset(state) {
      state.logs = []
      state.auditlogListParams = {}
    },
    setLogs(state, payload: { logs: AuditLogDto[]; listParams: ListRequestParams }) {
      state.logs = payload.logs
      state.auditlogListParams = payload.listParams
    }
  },
  effects: dispatch => {
    const { auditlog } = dispatch

    return {
      async getLogs(params: AuditLogApiGetAllAuditLogRequest = {}, state: RematchRootState<RootModel>): Promise<void> {
        const requestParams = { ...state.auditlog.auditlogListParams, ...params }

        const {
          data: { result, ...listParams }
        } = await api.auditlog.getAllAuditLog(requestParams)
        auditlog.setLogs({ logs: result || [], listParams })
      }
    }
  }
})
