import { createModel, RematchRootState } from '@rematch/core'
import { api } from '@/api'
import {
  CreateMonthlyMaintenanceCostDto,
  MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostRequest,
  MonthlyMaintenanceCostDto
} from '@/api/generated'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import type { RootModel } from '.'

type SettingsState = {
  monthlyMaintenanceCostList?: MonthlyMaintenanceCostDto[]
  monthlyMaintenanceCostListParams?: ListRequestParams
  monthlyMaintenanceCost?: MonthlyMaintenanceCostDto
}

export const settings = createModel<RootModel>()({
  state: {} as SettingsState,
  reducers: {
    reset(state) {
      state.monthlyMaintenanceCostList = []
      state.monthlyMaintenanceCostListParams = {}
    },
    setMonthlyCostList: (
      state,
      payload: {
        monthlyMaintenanceCosts: MonthlyMaintenanceCostDto[]
        listParams: ListRequestParams
      }
    ) => {
      state.monthlyMaintenanceCostList = payload.monthlyMaintenanceCosts
      state.monthlyMaintenanceCostListParams = payload.listParams
    },
    setMonthlyCost: (state, monthlyMaintenanceCost: MonthlyMaintenanceCostDto) => {
      state.monthlyMaintenanceCost = monthlyMaintenanceCost
    }
  },
  effects: dispatch => {
    const { settings } = dispatch

    return {
      async getMonthlyMaintenanceCosts(
        params: MonthlyMaintenanceCostApiGetMonthlyMaintenanceCostRequest = {},
        state: RematchRootState<RootModel>
      ): Promise<void> {
        const requestParams = { ...state.contract.contractListParams, ...params }

        const {
          data: { result, ...listParams }
        } = await api.monthlyMaintenanceCost.getMonthlyMaintenanceCost(requestParams)
        settings.setMonthlyCostList({ monthlyMaintenanceCosts: result || [], listParams })
      },

      async setMonthlyMaintenanceCost({ cost }: CreateMonthlyMaintenanceCostDto): Promise<void> {
        await api.monthlyMaintenanceCost.createMonthlyMaintenanceCost({
          createMonthlyMaintenanceCostDto: { cost }
        })
      },

      async getLastMonthlyMaintenanceCost(): Promise<void> {
        const { data } = await api.monthlyMaintenanceCost.getLastMonthlyMaintenanceCost()
        settings.setMonthlyCost(data)
      }
    }
  }
})
