import { createModel } from '@rematch/core'
import { api } from '@/api'
import {
  ModifyPasswordDto,
  PasswordResetDto,
  ProfileDto,
  RegTokenValidationResponseDto,
  UserApiDeleteUserEmailAdminRequest,
  UserApiSetPasswordRequest,
  UserApiTokenInfoRequest,
  UserApiUpdateUserEmailAdminRequest,
  UserApiUpdateUserRequest,
  UserApiUserRegistrationRequest
} from '@/api/generated'
import type { RootModel } from '.'

type ProfileState = {
  profile?: ProfileDto
}

export const profile = createModel<RootModel>()({
  state: {
    passwordChangeVisible: false
  } as ProfileState,
  reducers: {
    setProfile: (state, payload: ProfileDto) => {
      state.profile = payload
    }
  },
  effects: dispatch => {
    const { profile } = dispatch

    return {
      async tokenValidation({ token, tokenPurpose }: UserApiTokenInfoRequest): Promise<RegTokenValidationResponseDto> {
        const { data } = await api.user.tokenInfo({ token, tokenPurpose })
        return data
      },

      async registerProfile({
        token,
        isTermsAccepted,
        userRegistrationDto
      }: UserApiUserRegistrationRequest): Promise<void> {
        await api.user.userRegistration({ token, isTermsAccepted, userRegistrationDto })
      },

      async getProfile(id: string): Promise<void> {
        const { data } = await api.user.getUserById({ id })
        profile.setProfile(data)
      },

      async updateProfile({ id, updateUserDto }: UserApiUpdateUserRequest): Promise<void> {
        await api.user.updateUser({ id, updateUserDto })
      },

      //Update user own email address.
      async updateProfileEmail(newEmail: string): Promise<void> {
        await api.user.updateUserEmail({ newEmail })
      },

      async updateProfileEmailAdmin({ userId, newEmail }: UserApiUpdateUserEmailAdminRequest): Promise<void> {
        await api.user.updateUserEmailAdmin({ userId, newEmail })
      },

      async deleteProfileEmailAdmin({ userId }: UserApiDeleteUserEmailAdminRequest): Promise<void> {
        await api.user.deleteUserEmailAdmin({ userId })
      },

      async resetProfilePassword(passwordResetDto: PasswordResetDto): Promise<void> {
        await api.user.passwordReset({ passwordResetDto })
      },

      //Update existing password.
      async updateProfilePassword(modifyPasswordDto: ModifyPasswordDto): Promise<void> {
        await api.user.updatePassword({ modifyPasswordDto })
      },

      async setPassword({ token, changePasswordDto }: UserApiSetPasswordRequest): Promise<void> {
        await api.user.setPassword({ token, changePasswordDto })
      },

      async getCurrentUser(): Promise<void> {
        const { data } = await api.user.getUser()
        profile.setProfile(data)
      }
    }
  }
})
