import { createModel, RematchRootState } from '@rematch/core'
import { api } from '@/api'
import {
  CustomerAdminGridDto,
  CustomerApiCreateCompanyCustomerRequest,
  CustomerApiCreateIndividualCustomerRequest,
  CustomerApiGetByIdRequest,
  CustomerApiGetCustomerNotificationSettingRequest,
  CustomerApiGetCustomersRequest,
  CustomerApiGetStatusHistoryRequest,
  CustomerApiUpdateCompanyCustomerRequest,
  CustomerApiUpdateCustomerNotificationSettingForCustomerRequest,
  CustomerApiUpdateCustomerNotificationSettingRequest,
  CustomerApiUpdateIndividualCustomerRequest,
  CustomerDetailsDto,
  CustomerNotificationSettingsDto,
  CustomerTotalCountsDto,
  IdResponseDto,
  StatusHistoryDto,
  UserRegistrationStates
} from '@/api/generated'
import { ListRequestParams } from '@/components/customTable/hooks/useCustomTable'
import type { RootModel } from '.'

//TODO: remove extends
interface CustomerDetailsDtoWithExtras extends CustomerDetailsDto {
  registrationState?: UserRegistrationStates
  registrationDate?: string
}

type CustomersState = {
  customers?: CustomerAdminGridDto[]
  customerListParams?: ListRequestParams
  totalCounts?: CustomerTotalCountsDto
  customer?: CustomerDetailsDtoWithExtras | null
  notificationSettings?: CustomerNotificationSettingsDto
  statusHistory?: StatusHistoryDto[]
}

export const customers = createModel<RootModel>()({
  state: {} as CustomersState,
  reducers: {
    reset(state) {
      state.customers = []
      state.customerListParams = {}
    },
    resetCustomer(state) {
      state.customer = null
    },
    setCustomers(state, payload: { customers: CustomerAdminGridDto[]; listParams: ListRequestParams }) {
      state.customers = payload.customers
      state.customerListParams = payload.listParams
    },
    setTotalCounts(state, totalCounts: CustomerTotalCountsDto) {
      state.totalCounts = totalCounts
    },
    setCustomer(state, customer: CustomerDetailsDtoWithExtras) {
      state.customer = customer
    },
    setNotificationSettings(state, notificationSettings: CustomerNotificationSettingsDto) {
      state.notificationSettings = notificationSettings
    },
    setStatusHistory(state, history: StatusHistoryDto[]) {
      state.statusHistory = history
    }
  },
  effects: dispatch => {
    const { customers } = dispatch

    return {
      async getCustomers(
        params: CustomerApiGetCustomersRequest = {},
        state: RematchRootState<RootModel>
      ): Promise<CustomerAdminGridDto[]> {
        const requestParams = {
          ...state.customers.customerListParams,
          ...params,
          isArchived: false
        }

        const {
          data: { result, ...listParams }
        } = await api.customer.getCustomers(requestParams)
        customers.setCustomers({ customers: result || [], listParams })

        return result || []
      },

      async getCustomersWithArchive(
        params: CustomerApiGetCustomersRequest = {},
        state: RematchRootState<RootModel>
      ): Promise<CustomerAdminGridDto[]> {
        const requestParams = {
          ...state.customers.customerListParams,
          ...params
        }

        const {
          data: { result, ...listParams }
        } = await api.customer.getCustomers(requestParams)
        customers.setCustomers({ customers: result || [], listParams })

        return result || []
      },

      async createIndividualCustomer({
        createIndividualCustomerDto
      }: CustomerApiCreateIndividualCustomerRequest): Promise<IdResponseDto> {
        const { data } = await api.customer.createIndividualCustomer({
          createIndividualCustomerDto
        })
        return data
      },

      async createCompanyCustomer({
        createCompanyCustomerDto
      }: CustomerApiCreateCompanyCustomerRequest): Promise<IdResponseDto> {
        const { data } = await api.customer.createCompanyCustomer({ createCompanyCustomerDto })
        return data
      },

      async updateIndividualCustomer({
        id,
        updateIndividualCustomerDto
      }: CustomerApiUpdateIndividualCustomerRequest): Promise<void> {
        await api.customer.updateIndividualCustomer({ id, updateIndividualCustomerDto })
      },

      async updateCompanyCustomer({
        id,
        updateCompanyCustomerDto
      }: CustomerApiUpdateCompanyCustomerRequest): Promise<void> {
        await api.customer.updateCompanyCustomer({ id, updateCompanyCustomerDto })
      },

      async getCustomer({ id }: CustomerApiGetByIdRequest): Promise<CustomerDetailsDtoWithExtras> {
        const { data } = await api.customer.getById({ id })
        customers.setCustomer(data)

        return data
      },

      async archiveCustomer(customerId: number): Promise<void> {
        await api.customer.archiveCustomer({ customerId })
      },

      async activateCustomer(customerId: number): Promise<void> {
        await api.customer.reactivateCustomer({ customerId })
      },

      async getTotalCounts(): Promise<void> {
        const { data } = await api.customer.customerTotalCounts()
        customers.setTotalCounts(data)
      },

      async getNotificationSettings({ id }: CustomerApiGetCustomerNotificationSettingRequest): Promise<void> {
        const { data } = await api.customer.getCustomerNotificationSetting({ id })
        customers.setNotificationSettings(data)
      },

      async updateNotificationSetting({
        id,
        updateCustomerNotificationSettingsDto
      }: CustomerApiUpdateCustomerNotificationSettingRequest): Promise<void> {
        await api.customer.updateCustomerNotificationSetting({
          id,
          updateCustomerNotificationSettingsDto
        })
      },

      async getNotificationSettingForCustomer(): Promise<void> {
        const { data } = await api.customer.getCustomerNotificationSettingForCustomer()
        customers.setNotificationSettings(data)
      },

      async updateNotificationSettingForCustomer({
        updateCustomerNotificationSettingsDto
      }: CustomerApiUpdateCustomerNotificationSettingForCustomerRequest): Promise<void> {
        await api.customer.updateCustomerNotificationSettingForCustomer({
          updateCustomerNotificationSettingsDto
        })
      },

      async getStatusHistory({ userId }: CustomerApiGetStatusHistoryRequest): Promise<void> {
        const { data } = await api.customer.getStatusHistory({ userId })
        customers.setStatusHistory(data)
      }
    }
  }
})
