/* tslint:disable */
/* eslint-disable */
/**
 * EN.ClientContract
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Breakdowns } from '../models';
// @ts-ignore
import { ChartDto } from '../models';
// @ts-ignore
import { CustomerAgeSummaryDto } from '../models';
// @ts-ignore
import { DeviceStatisticsByCategoryDto } from '../models';
// @ts-ignore
import { StatementAllResultDto } from '../models';
// @ts-ignore
import { StatementByResultDto } from '../models';
/**
 * StatementApi - axios parameter creator
 * @export
 */
export const StatementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Customer age statistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAgeSummary: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statement/Customer/AgeSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Number of customers.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerStats: async (year?: number, month?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statement/Customer/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Number of devices for every device category.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceByCategoryStats: async (year?: number, month?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statement/DeviceCategory/Devices/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Number of devices.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStats: async (year?: number, month?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statement/Devices/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns provider report csv.
         * @param {Array<string>} received 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderReport: async (received: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'received' is not null or undefined
            assertParamExists('getProviderReport', 'received', received)
            const localVarPath = `/api/Statement/ProviderReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (received) {
                localVarQueryParameter['Received'] = received;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gel EN all statement.
         * @param {string} startDate 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementAll: async (startDate: string, breakdown?: Breakdowns, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('statementAll', 'startDate', startDate)
            const localVarPath = `/api/Statement/StatementAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (breakdown !== undefined) {
                localVarQueryParameter['Breakdown'] = breakdown;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get EN statement by contract.
         * @param {string} startDate 
         * @param {Array<number>} [ids] 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementByContract: async (startDate: string, ids?: Array<number>, breakdown?: Breakdowns, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('statementByContract', 'startDate', startDate)
            const localVarPath = `/api/Statement/StatementByContract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (breakdown !== undefined) {
                localVarQueryParameter['Breakdown'] = breakdown;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get EN statement by customers.
         * @param {Array<number>} ids 
         * @param {string} startDate 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementByCustomer: async (ids: Array<number>, startDate: string, breakdown?: Breakdowns, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('statementByCustomer', 'ids', ids)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('statementByCustomer', 'startDate', startDate)
            const localVarPath = `/api/Statement/StatementByCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (breakdown !== undefined) {
                localVarQueryParameter['Breakdown'] = breakdown;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get EN statement by device.
         * @param {string} startDate 
         * @param {Array<number>} [ids] 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementByDevice: async (startDate: string, ids?: Array<number>, breakdown?: Breakdowns, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('statementByDevice', 'startDate', startDate)
            const localVarPath = `/api/Statement/StatementByDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication XApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (breakdown !== undefined) {
                localVarQueryParameter['Breakdown'] = breakdown;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatementApi - functional programming interface
 * @export
 */
export const StatementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Customer age statistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAgeSummary(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAgeSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAgeSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Number of customers.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerStats(year?: number, month?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerStats(year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Number of devices for every device category.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceByCategoryStats(year?: number, month?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceStatisticsByCategoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceByCategoryStats(year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Number of devices.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceStats(year?: number, month?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceStats(year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns provider report csv.
         * @param {Array<string>} received 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderReport(received: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderReport(received, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gel EN all statement.
         * @param {string} startDate 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementAll(startDate: string, breakdown?: Breakdowns, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementAllResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementAll(startDate, breakdown, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get EN statement by contract.
         * @param {string} startDate 
         * @param {Array<number>} [ids] 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementByContract(startDate: string, ids?: Array<number>, breakdown?: Breakdowns, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementByResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementByContract(startDate, ids, breakdown, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get EN statement by customers.
         * @param {Array<number>} ids 
         * @param {string} startDate 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementByCustomer(ids: Array<number>, startDate: string, breakdown?: Breakdowns, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementByResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementByCustomer(ids, startDate, breakdown, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get EN statement by device.
         * @param {string} startDate 
         * @param {Array<number>} [ids] 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementByDevice(startDate: string, ids?: Array<number>, breakdown?: Breakdowns, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementByResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementByDevice(startDate, ids, breakdown, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatementApi - factory interface
 * @export
 */
export const StatementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatementApiFp(configuration)
    return {
        /**
         * 
         * @summary Customer age statistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAgeSummary(options?: any): AxiosPromise<CustomerAgeSummaryDto> {
            return localVarFp.customerAgeSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Number of customers.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerStats(year?: number, month?: number, options?: any): AxiosPromise<ChartDto> {
            return localVarFp.customerStats(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Number of devices for every device category.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceByCategoryStats(year?: number, month?: number, options?: any): AxiosPromise<DeviceStatisticsByCategoryDto> {
            return localVarFp.deviceByCategoryStats(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Number of devices.
         * @param {number} [year] Year.
         * @param {number} [month] Month. Optional, if you want to see daily stats.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceStats(year?: number, month?: number, options?: any): AxiosPromise<ChartDto> {
            return localVarFp.deviceStats(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns provider report csv.
         * @param {Array<string>} received 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderReport(received: Array<string>, options?: any): AxiosPromise<any> {
            return localVarFp.getProviderReport(received, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gel EN all statement.
         * @param {string} startDate 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementAll(startDate: string, breakdown?: Breakdowns, options?: any): AxiosPromise<StatementAllResultDto> {
            return localVarFp.statementAll(startDate, breakdown, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get EN statement by contract.
         * @param {string} startDate 
         * @param {Array<number>} [ids] 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementByContract(startDate: string, ids?: Array<number>, breakdown?: Breakdowns, options?: any): AxiosPromise<StatementByResultDto> {
            return localVarFp.statementByContract(startDate, ids, breakdown, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get EN statement by customers.
         * @param {Array<number>} ids 
         * @param {string} startDate 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementByCustomer(ids: Array<number>, startDate: string, breakdown?: Breakdowns, options?: any): AxiosPromise<StatementByResultDto> {
            return localVarFp.statementByCustomer(ids, startDate, breakdown, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get EN statement by device.
         * @param {string} startDate 
         * @param {Array<number>} [ids] 
         * @param {Breakdowns} [breakdown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementByDevice(startDate: string, ids?: Array<number>, breakdown?: Breakdowns, options?: any): AxiosPromise<StatementByResultDto> {
            return localVarFp.statementByDevice(startDate, ids, breakdown, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for customerStats operation in StatementApi.
 * @export
 * @interface StatementApiCustomerStatsRequest
 */
export interface StatementApiCustomerStatsRequest {
    /**
     * Year.
     * @type {number}
     * @memberof StatementApiCustomerStats
     */
    readonly year?: number

    /**
     * Month. Optional, if you want to see daily stats.
     * @type {number}
     * @memberof StatementApiCustomerStats
     */
    readonly month?: number
}

/**
 * Request parameters for deviceByCategoryStats operation in StatementApi.
 * @export
 * @interface StatementApiDeviceByCategoryStatsRequest
 */
export interface StatementApiDeviceByCategoryStatsRequest {
    /**
     * Year.
     * @type {number}
     * @memberof StatementApiDeviceByCategoryStats
     */
    readonly year?: number

    /**
     * Month. Optional, if you want to see daily stats.
     * @type {number}
     * @memberof StatementApiDeviceByCategoryStats
     */
    readonly month?: number
}

/**
 * Request parameters for deviceStats operation in StatementApi.
 * @export
 * @interface StatementApiDeviceStatsRequest
 */
export interface StatementApiDeviceStatsRequest {
    /**
     * Year.
     * @type {number}
     * @memberof StatementApiDeviceStats
     */
    readonly year?: number

    /**
     * Month. Optional, if you want to see daily stats.
     * @type {number}
     * @memberof StatementApiDeviceStats
     */
    readonly month?: number
}

/**
 * Request parameters for getProviderReport operation in StatementApi.
 * @export
 * @interface StatementApiGetProviderReportRequest
 */
export interface StatementApiGetProviderReportRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof StatementApiGetProviderReport
     */
    readonly received: Array<string>
}

/**
 * Request parameters for statementAll operation in StatementApi.
 * @export
 * @interface StatementApiStatementAllRequest
 */
export interface StatementApiStatementAllRequest {
    /**
     * 
     * @type {string}
     * @memberof StatementApiStatementAll
     */
    readonly startDate: string

    /**
     * 
     * @type {Breakdowns}
     * @memberof StatementApiStatementAll
     */
    readonly breakdown?: Breakdowns
}

/**
 * Request parameters for statementByContract operation in StatementApi.
 * @export
 * @interface StatementApiStatementByContractRequest
 */
export interface StatementApiStatementByContractRequest {
    /**
     * 
     * @type {string}
     * @memberof StatementApiStatementByContract
     */
    readonly startDate: string

    /**
     * 
     * @type {Array<number>}
     * @memberof StatementApiStatementByContract
     */
    readonly ids?: Array<number>

    /**
     * 
     * @type {Breakdowns}
     * @memberof StatementApiStatementByContract
     */
    readonly breakdown?: Breakdowns
}

/**
 * Request parameters for statementByCustomer operation in StatementApi.
 * @export
 * @interface StatementApiStatementByCustomerRequest
 */
export interface StatementApiStatementByCustomerRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof StatementApiStatementByCustomer
     */
    readonly ids: Array<number>

    /**
     * 
     * @type {string}
     * @memberof StatementApiStatementByCustomer
     */
    readonly startDate: string

    /**
     * 
     * @type {Breakdowns}
     * @memberof StatementApiStatementByCustomer
     */
    readonly breakdown?: Breakdowns
}

/**
 * Request parameters for statementByDevice operation in StatementApi.
 * @export
 * @interface StatementApiStatementByDeviceRequest
 */
export interface StatementApiStatementByDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof StatementApiStatementByDevice
     */
    readonly startDate: string

    /**
     * 
     * @type {Array<number>}
     * @memberof StatementApiStatementByDevice
     */
    readonly ids?: Array<number>

    /**
     * 
     * @type {Breakdowns}
     * @memberof StatementApiStatementByDevice
     */
    readonly breakdown?: Breakdowns
}

/**
 * StatementApi - object-oriented interface
 * @export
 * @class StatementApi
 * @extends {BaseAPI}
 */
export class StatementApi extends BaseAPI {
    /**
     * 
     * @summary Customer age statistics.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public customerAgeSummary(options?: any) {
        return StatementApiFp(this.configuration).customerAgeSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Number of customers.
     * @param {StatementApiCustomerStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public customerStats(requestParameters: StatementApiCustomerStatsRequest = {}, options?: any) {
        return StatementApiFp(this.configuration).customerStats(requestParameters.year, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Number of devices for every device category.
     * @param {StatementApiDeviceByCategoryStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public deviceByCategoryStats(requestParameters: StatementApiDeviceByCategoryStatsRequest = {}, options?: any) {
        return StatementApiFp(this.configuration).deviceByCategoryStats(requestParameters.year, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Number of devices.
     * @param {StatementApiDeviceStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public deviceStats(requestParameters: StatementApiDeviceStatsRequest = {}, options?: any) {
        return StatementApiFp(this.configuration).deviceStats(requestParameters.year, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns provider report csv.
     * @param {StatementApiGetProviderReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public getProviderReport(requestParameters: StatementApiGetProviderReportRequest, options?: any) {
        return StatementApiFp(this.configuration).getProviderReport(requestParameters.received, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gel EN all statement.
     * @param {StatementApiStatementAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public statementAll(requestParameters: StatementApiStatementAllRequest, options?: any) {
        return StatementApiFp(this.configuration).statementAll(requestParameters.startDate, requestParameters.breakdown, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get EN statement by contract.
     * @param {StatementApiStatementByContractRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public statementByContract(requestParameters: StatementApiStatementByContractRequest, options?: any) {
        return StatementApiFp(this.configuration).statementByContract(requestParameters.startDate, requestParameters.ids, requestParameters.breakdown, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get EN statement by customers.
     * @param {StatementApiStatementByCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public statementByCustomer(requestParameters: StatementApiStatementByCustomerRequest, options?: any) {
        return StatementApiFp(this.configuration).statementByCustomer(requestParameters.ids, requestParameters.startDate, requestParameters.breakdown, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get EN statement by device.
     * @param {StatementApiStatementByDeviceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatementApi
     */
    public statementByDevice(requestParameters: StatementApiStatementByDeviceRequest, options?: any) {
        return StatementApiFp(this.configuration).statementByDevice(requestParameters.startDate, requestParameters.ids, requestParameters.breakdown, options).then((request) => request(this.axios, this.basePath));
    }
}
