import { FC, useEffect } from 'react'
import i18n from '@/i18n'
import { useDispatch, useSelector } from '@/store'
import CustomErrorMessage from './CustomErrorMessage'

const CustomErrorHandler: FC = () => {
  const dispatch = useDispatch()
  const { list } = useSelector(state => state.errors)
  const globalErrors = list
    ?.filter(error => error?.errors?.ENError)
    ?.map(error => error?.errors?.ENError)
    ?.map((code: any) => {
      if (i18n.exists(`error.codes.${code}`)) {
        return i18n.t(`error.codes.${code}`)
      }

      return i18n.t('common.error')
    })
    ?.map((body: string, index: number) => <CustomErrorMessage key={index} body={body} timeout={5} />)

  useEffect(() => {
    if (globalErrors?.length) {
      dispatch.errors.reset()
    }
  }, [globalErrors])

  return <>{globalErrors}</>
}

export default CustomErrorHandler
