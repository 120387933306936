import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { mdiAccountCircle, mdiFormTextboxPassword, mdiLogout } from '@mdi/js'
import MaterialIcon from '@/components/icon/MaterialIcon'
import { useModalUtils } from '@/hooks/useModalUtils'
import { Role } from '@/models/role'
import PasswordChangeModal from '@/shared/passwordChangeModal/PasswordChangeModal'
import { useDispatch, useSelector } from '@/store'
import styles from './UserMenu.module.scss'

interface UserMenuItem {
  key: string
  requiredRole?: Role[]
  icon: string
  label: string
  url?: string
}

interface UserMenuProps {
  handleMenuClick?: () => void
}

const UserMenu: FC<UserMenuProps> = ({ handleMenuClick }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const passwordChangeModalUtils = useModalUtils()
  const { user } = useSelector(state => state.auth)

  const handleClick = ({ key }: any): void => {
    if (key === 'passwordChange') {
      passwordChangeModalUtils.open()
    } else if (key === 'logout') {
      dispatch.auth.logout()
    }

    if (handleMenuClick) {
      handleMenuClick()
    }
  }

  const menuItems: UserMenuItem[] = [
    {
      key: 'myProfile',
      icon: mdiAccountCircle,
      label: t('usermenu.my-profile'),
      url: '/me'
    },
    {
      key: 'passwordChange',
      icon: mdiFormTextboxPassword,
      label: t('usermenu.password-change')
    },
    {
      key: 'logout',
      icon: mdiLogout,
      label: t('usermenu.logout')
    }
  ]

  const items = menuItems
    .filter(item => {
      if (item?.requiredRole && user) {
        return item?.requiredRole?.includes(user?.role)
      }

      return true
    })
    .map(item => {
      const Wrap: FC = ({ children }) => (item?.url && <Link to={item.url}>{children}</Link>) || <>{children}</>

      return (
        <Menu.Item key={item.key} icon={<MaterialIcon path={item.icon} />}>
          <Wrap>{item.label}</Wrap>
        </Menu.Item>
      )
    })

  return (
    <>
      <Menu className={styles.userMenu} onClick={handleClick}>
        {items}
      </Menu>

      <PasswordChangeModal modalUtils={passwordChangeModalUtils} />
    </>
  )
}

export default UserMenu
