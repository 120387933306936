import { FC } from 'react'
import { Col, Divider, DividerProps } from 'antd'
import classNames from 'classnames'
import styles from './VerticalDivider.module.scss'

interface VerticalDividerProps extends DividerProps {
  dashed?: boolean
  order?: number
  className?: string
}

export const VerticalDivider: FC<VerticalDividerProps> = ({ dashed, order, className }) => {
  return (
    <Col className={classNames(styles.splitterContainer, className)} order={order} style={{ padding: '0px' }}>
      <Divider type="vertical" className={styles.verticalSplitter} dashed={dashed} />
    </Col>
  )
}
