import { useCallback, useState } from 'react'

export interface ModalState {
  visible: boolean
  params?: any
}

export interface ModalUtils {
  state: ModalState
  open: (params?: any) => void
  close: () => void
}

export const useModalUtils = (): ModalUtils => {
  const [state, setState] = useState<ModalState>({ visible: false })

  const open = useCallback((params?: any): void => {
    setState({ visible: true, params })
  }, [])

  const close = useCallback((): void => {
    setState({ visible: false })
  }, [])

  return {
    state,
    open,
    close
  }
}
