import { createModel } from '@rematch/core'
import { api } from '@/api'
import {
  ChartDto,
  CustomerAgeSummaryDto,
  StatementApiGetProviderReportRequest,
  StatementApiStatementAllRequest,
  StatementApiStatementByContractRequest,
  StatementApiStatementByCustomerRequest,
  StatementApiStatementByDeviceRequest,
  StatementByResultItemDto
} from '@/api/generated'
import { downloadBlob } from '@/utils/file/downloadBlob'
import type { RootModel } from '.'

interface StatementType extends Omit<StatementByResultItemDto, 'id'> {
  id?: string | number
}

type StatementTypeState = {
  currentStatement?: StatementType[]
  currentStatistics?: number[][] | null
}

export const statement = createModel<RootModel>()({
  state: {} as StatementTypeState,
  reducers: {
    reset(state) {
      state.currentStatement = []
    },
    setCurrentStatement(state, payload: StatementType[]) {
      state.currentStatement = payload
    },
    setCurrentStatistics(state, payload: ChartDto) {
      state.currentStatistics = payload?.values
    }
  },
  effects: dispatch => {
    const { statement } = dispatch

    return {
      async getStatementAll(params: StatementApiStatementAllRequest): Promise<void> {
        const { data } = await api.statement.statementAll(params)
        const { predictedConsumption, predictedProduction, ...rest } = data
        const update = rest?.consumption?.length ? [{ ...rest, id: 'en-summary' }] : []

        if (predictedConsumption?.length || predictedProduction?.length) {
          update.push({
            consumption: predictedConsumption || [],
            production: predictedProduction || [],
            id: 'en-prognosis'
          })
        }

        statement.setCurrentStatement(update)
      },

      async getStatementByCustomer(params: StatementApiStatementByCustomerRequest): Promise<void> {
        const {
          data: { result }
        } = await api.statement.statementByCustomer(params)

        statement.setCurrentStatement(result || [])
      },

      async getStatementByContract(
        params: StatementApiStatementByContractRequest
      ): Promise<StatementByResultItemDto[] | null | undefined> {
        const {
          data: { result }
        } = await api.statement.statementByContract(params)

        statement.setCurrentStatement(result || [])
        return result
      },

      async getStatementByDevice(
        params: StatementApiStatementByDeviceRequest
      ): Promise<StatementByResultItemDto[] | null | undefined> {
        const {
          data: { result }
        } = await api.statement.statementByDevice(params)

        statement.setCurrentStatement(result || [])
        return result
      },

      async getProviderReport({ received }: StatementApiGetProviderReportRequest): Promise<void> {
        const [from, to] = received
        const { data, headers } = await api.statement.getProviderReport({ received }, { responseType: 'blob' })
        const [, filename] = headers['content-disposition']?.match('filename=([^;]*)')

        downloadBlob(data, filename || `report-${from}-${to}`)
      },

      async getCustomerAgeSummary(): Promise<CustomerAgeSummaryDto> {
        const { data } = await api.statement.customerAgeSummary()
        return data
      },

      async getCustomerStats(params: { year?: number; month?: number }): Promise<void> {
        const { data } = await api.statement.customerStats(params)

        statement.setCurrentStatistics(data || null)
      },

      async getDeviceStats(params: { year?: number; month?: number }): Promise<void> {
        const { data } = await api.statement.deviceStats(params)

        statement.setCurrentStatistics(data || null)
      }
    }
  }
})
