import { createModel } from '@rematch/core'
import { api } from '@/api'
import { ChartDto } from '@/api/generated/models'
import type { RootModel } from '.'

type EnergyCostState = {
  energyCost?: ChartDto['values']
}

export const energyCost = createModel<RootModel>()({
  state: {
    energyCost: null
  } as EnergyCostState,
  reducers: {
    setEnergyCost: (state, payload: ChartDto) => {
      state.energyCost = payload?.values
    }
  },
  effects: dispatch => {
    const { energyCost } = dispatch

    return {
      async getEnergyCost(fromDate: string): Promise<void> {
        const { data } = await api.energyCost.getEnergyCosts({ fromDate })
        energyCost.setEnergyCost(data)
      }
    }
  }
})
