import useFormUtils, { FormUtils } from '@/hooks/useFormUtils'
import { SchemaKey } from '@/utils/schema'
import CustomForm, { CustomFormComponent } from './CustomForm'

type customFormFactoryProp = {
  formUtils: FormUtils
  CustomForm: CustomFormComponent
}

export const createCustomForm = (...args: Array<SchemaKey | string[]>): customFormFactoryProp => {
  const formUtils = useFormUtils(...args)

  return {
    formUtils,
    CustomForm: CustomForm
  }
}
