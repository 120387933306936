import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Divider, Drawer, DrawerProps } from 'antd'
import { mdiCloseCircleOutline } from '@mdi/js'
import classNames from 'classnames'
import Logo from '@/assets/images/panerg-alter-8.png'
import MaterialIcon from '@/components/icon/MaterialIcon'
import TopMenu from '@/components/layout/components/TopMenu/TopMenu'
import UserMenu from '@/components/layout/components/UserMenu/UserMenu'
import styles from './DrawerMenu.module.scss'

const DrawerMenu: FC<DrawerProps> = ({ className, ...props }) => {
  return (
    <Drawer
      placement="left"
      className={classNames(styles.drawerMenu, className)}
      closeIcon={<MaterialIcon path={mdiCloseCircleOutline} size="24px" />}
      extra={
        <Link to="/">
          <img src={Logo} className={styles.logo} />
        </Link>
      }
      {...props}
    >
      <TopMenu mode="vertical" />
      <Divider />
      <UserMenu />
    </Drawer>
  )
}

export default DrawerMenu
