import { FC } from 'react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router'

// force refresh component by update a new reference in state
export const Refresher: FC = ({ children }): JSX.Element => {
  const location = useLocation()
  const [, dispatch] = useState(Object.create(null))
  const [prevLocation, setPrevLocation] = useState<any>()
  const [forceRefresh, setForceRefresh] = useState<boolean>(false)

  useEffect(() => {
    const shouldRefresh =
      prevLocation &&
      prevLocation.pathname === location.pathname &&
      prevLocation.search === location.search &&
      prevLocation.hash === location.hash &&
      prevLocation.key !== location.key

    setForceRefresh(shouldRefresh)
    setPrevLocation(location)
  }, [setForceRefresh, prevLocation, location.key])

  useLayoutEffect(() => {
    if (forceRefresh) {
      dispatch(Object.create(null))
    }
  }, [dispatch, forceRefresh])

  return <>{forceRefresh || children}</>
}
